
.header {


    a{
        &:hover{
            color: $red;
            transition: 0.2s;
        }
    }
    // .header__bottom

    &__bottom {
        background-color: $white;
        padding: 22px 0;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        @media(max-width:$md2){
            transition: 0.3s;
            position: fixed;
            right: -700px;
            height: 100dvh;
            max-width: 350px;
            width: 100%;
            z-index: 100;
            top: 0;
            padding: 0;
            overflow: auto;
            .header__logo{
                display: none;
            }
        }
        &.active-menu{
            right: 0;
        }
    }

    &__bottom-cabinet{
        padding: 42px 0;
        .header__top-numbers{
            display: none;
        }
        .header__menu-list{
            max-width: 360px;
        }
        .header__burger{
            display: none;
        }
        @media(max-width:$md2){
            background-color: $background;
            padding: 24px 0;
            .header__menu-list{
                display: none;
            }
            .header__top-numbers{
                display: flex;
            }
            .header__burger{
                display: block;
            }
        }
    }

    // .header__bottom-container

    &__bottom-container {
        display: flex;
        justify-content: space-between;
        gap: 15px;
        align-items: center;
        @media(max-width:$md2){
            flex-direction: column;
            height: 100%;
        }
    }

    // .header__logo

    &__logo {
        font-size: 0;
        @media(max-width:$md2){
            max-width: 125px;
            max-height: 37px;
        }
    }

    // .header__menu-list

    &__menu-list {
        display: flex;
        max-width: 600px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
        .active {
            color: $red;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            cursor: default;
        }
    }

    // .header__menu-list-li

    &__menu-list-li {
        position: relative;

        &:hover{
            .header__margin-top{
                // transform: translateY(35px);
                transition: 0.3s;
                opacity: 1;
                visibility: visible;
                @media(max-width:$md2){
                    margin-top: 0;
                }
            }
            .header__menu-title{
                p{
                    color: $red;
                    transition: 0.2s;
                }
                svg{
                    path{
                        stroke:$red;
                        transition: 0.2s;
                    }
                }
            }
            .header__menu-sublist{
                // transform: translateY(35px);
                transition: 0.3s;
                opacity: 1;
                visibility: visible;
                @media(max-width:$md2){
                    margin-top: 0;
                }
            }
        }
    }

    // .header__menu-title

    &__menu-title {
        display: flex;
        cursor: pointer;
        gap: 8px;
        align-items: center;
        white-space: nowrap;
        transition: .3s;
        p{
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: $title;
            white-space: nowrap;
        }
        svg{
            transition: .3s;
        }
        a{
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: $title;
        }
        &:hover{
            p{
                color: $red;
                transition: 0.2s;
            }
            svg{
                path{
                    stroke:$red;
                    transition: 0.2s;
                }
            }
        }
    }

    // .header__menu-sublist
    .header__margin-top{
        margin-top: 20px;
        padding-top: 15px;
        background-color: transparent;
        visibility: hidden;
        opacity: 0;
        transition: 0.3s;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 2;
    }

    &__menu-sublist {
        visibility: hidden;
        opacity: 0;
        transition: 0.3s;
        top: 0;
        left: 0;
        // position: absolute;
        z-index: 2;
        background: $white;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 24px;
        min-width: 170px;
        max-width: 250px;
        width: max-content;
    }

    .first-list_active{
        margin-top: 35px;
        transition: 0.3s;
        opacity: 1;
        visibility: visible;
        @media(max-width:$md2){
        margin-top: 0;
        }
    }

    // .header__menu-sublist-link


    &__menu-sublist-link {
        position: relative;
        a{
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: $text3;
        }
        p{
            word-break: break-word;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: $text3;
        }
        &:hover{
            .header__menu-sublist-title{

                p{
                    color: $red;
                    transition: 0.2s;
                }

                svg{
                    path{
                        stroke:$red;
                        transition: 0.2s;
                    }
                }

            }
            .header__menu-margin-left{
                opacity: 1;
                visibility: visible;
                z-index: 2;
                transition: .3s;
            }
            .header__menu-lastlist{
                // left: 115%;
                opacity: 1;
                visibility: visible;
                z-index: 2;
                transition: .3s;
            }
        }
    }

    // .header__menu-sublist-title

    &__menu-sublist-title {
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        p{
            max-width: 160px;
        }
        svg{
            width: 7px;
            min-width: 7px;
            max-width: 7px;
            min-height: 13px;
            height: 13px;
            max-height: 13px;
            transition: .3s;
            path{
                stroke:$text3;

                display: flex;
            }
        }
        transition: .3s;
        &:hover{
            p{
                color: $red;
                transition: 0.2s;
            }
            svg{
                path{
                    stroke:$red;
                    transition: 0.2s;
                }
            }
        }
    }

    // .header__menu-lastlist
    // .header__menu-margin-left

    &__menu-margin-left{
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 100%;
        padding-left: 35px;
        background-color: transparent;
    }


    &__menu-lastlist {
        opacity: 0;
        visibility: hidden;
        // position: absolute;
        top: 0;
        left: 0;
        background: $white;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
        padding: 15px 24px;
        max-width: 248px;
        width: 248px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        transition: .3s;
    }

    .lastlist-active{
        left: 115%;
        opacity: 1;
        visibility: visible;
        z-index: 2;
        transition: .3s;
    }

    // .header__menu-lastlist-link

    &__menu-lastlist-link {
        a{
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: $subtitle;
        }

    }

    // .header__button

    &__button {
        &:hover{
            color: $white !important;
        }
        @media(max-width:$md2){
            display: none;
        }
    }

    &__leave-cabinet{
        display: flex;
        align-items: center;
        width: max-content;
        gap: 12px;
        padding: 16px 5px;
        white-space: nowrap;
        max-width: 230px;
        width: 100%;
        @media(max-width:$md2){
            display: none;
        }
    }
    &__mobile-leave-btn{
        display: flex;
        align-items: center;
        width: max-content;
        gap: 12px;
        padding: 16px 5px;
        white-space: nowrap;
        max-width: 280px;
        width: 100%;
    }

    &__block-for-mobile{
        display: none;
        gap: 30px;
        align-items: center;
        @media(max-width:$md2){
            display: flex;
        }
    }
}

.header2{
    a{
        &:hover{
            color: $red;
            transition: 0.2s;
        }
    }
    // .header__bottom

    &__bottom {
        padding: 42px 0;
        @media(max-width:$md2){
            display: none;
        }
    }

    &__bottom-cabinet{
        padding: 42px 0;
        .header__top-numbers{
            display: none;
        }
        .header__menu-list{
            max-width: 360px;
        }
        .header__burger{
            display: none;
        }
        @media(max-width:$md2){
            background-color: $background;
            padding: 24px 0;
            .header__menu-list{
                display: none;
            }
            .header__top-numbers{
                display: flex;
            }
            .header__burger{
                display: block;
            }
        }
    }

    // .header__bottom-container

    &__bottom-container {
        display: flex;
        justify-content: space-between;
        gap: 15px;
        align-items: center;
    }

    // .header__logo

    &__logo {
        font-size: 0;
        @media(max-width:$md2){
            max-width: 125px;
            max-height: 37px;
        }
    }

    // .header__menu-list

    &__menu-list {
        display: flex;
        max-width: 530px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
    }

    // .header__menu-list-li

    &__menu-list-li {
        position: relative;

        &:hover{
            .header__menu-sublist{
                margin-top: 35px;
                transition: 0.3s;
                opacity: 1;
                visibility: visible;
                @media(max-width:$md2){
                    margin-top: 0;
                }
            }
        }
    }

    // .header__menu-title

    &__menu-title {
        display: flex;
        cursor: pointer;
        gap: 8px;
        align-items: center;
        transition: .3s;
        p{
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: $title;
        }
        svg{
            transition: .3s;
        }
        a{
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: $title;
        }
        &:hover{
            p{
                color: $red;
                transition: 0.2s;
            }
            svg{
                path{
                    stroke:$red;
                    transition: 0.2s;
                }
            }
        }
    }

    // .header__menu-sublist

    &__menu-sublist {
        visibility: hidden;
        opacity: 0;
        transition: 0.3s;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 2;
        background: $white;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 24px;
        min-width: 170px;
        max-width: 250px;
        width: max-content;
    }

    .first-list_active{
        margin-top: 35px;
        transition: 0.3s;
        opacity: 1;
        visibility: visible;
        @media(max-width:$md2){
        margin-top: 0;
        }
    }

    // .header__menu-sublist-link

    &__menu-sublist-link {
        position: relative;
        a{
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: $text3;
        }
        p{
            word-break: break-word;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: $text3;
        }
    }

    // .header__menu-sublist-title

    &__menu-sublist-title {
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        p{
            max-width: 160px;
        }
        svg{
            width: 7px;
            min-width: 7px;
            max-width: 7px;
            min-height: 13px;
            height: 13px;
            max-height: 13px;
            transition: .3s;
            path{
                stroke:$text3;

                display: flex;
            }
        }
        transition: .3s;
        &:hover{
            p{
                color: $red;
                transition: 0.2s;
            }
            svg{
                path{
                    stroke:$red;
                    transition: 0.2s;
                }
            }
        }
    }

    // .header__menu-lastlist

    &__menu-lastlist {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 110%;
        background: $white;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
        padding: 15px 24px;
        max-width: 248px;
        width: 248px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        transition: .3s;
    }

    .lastlist-active{
        left: 115%;
        opacity: 1;
        visibility: visible;
        z-index: 2;
        transition: .3s;
    }

    // .header__menu-lastlist-link

    &__menu-lastlist-link {
        a{
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: $subtitle;
        }

    }

    // .header__button

    &__button {
        &:hover{
            color: $white !important;
        }
        @media(max-width:$md2){
            display: none;
        }
    }

    &__leave-cabinet{
        display: flex;
        align-items: center;
        width: max-content;
        gap: 12px;
        padding: 16px 5px;
        white-space: nowrap;
        max-width: 230px;
        width: 100%;
        @media(max-width:$md2){
            display: none;
        }
    }
    &__mobile-leave-btn{
        display: flex;
        align-items: center;
        width: max-content;
        gap: 12px;
        padding: 16px 5px;
        white-space: nowrap;
        max-width: 280px;
        width: 100%;
    }

    &__block-for-mobile{
        display: none;
        gap: 30px;
        align-items: center;
        @media(max-width:$md2){
            display: flex;
        }
    }
}


.title-active{
    transition: .3s;
    p{
        color: $red;
    }
    svg{
        transform: rotate(180deg);
        transition: .3s;
        path{
            stroke: $red !important;
        }

        // rotate: 180deg;
    }
}
.subtitle-active{
    transition: .3s;
    p{
        color: $red;
    }
    svg{
        transform: rotate(180deg);
        transition: .3s;
        path{
            stroke: $red !important;
        }

        // rotate: 180deg;
    }
}
