.answer {

    &__container{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 56px;
        @media(max-width: $md3){
            gap: 36px;
        }
    }
    // .faq__title

    &__title {
        color: $title;
    }

    &__content {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: 32px;
        @media(max-width:$md3){
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 0px;
        }

        &-container {
            flex: 1 1 300px;
            width: 100%;
            @media(max-width:$md3){
                display: flex;
                flex-direction: column;
                width: 100%;
                flex: none;
            }
        }

        &-block{
            width: 100%;
            padding: 16px 0px;
            background-color: $white;
            display: grid;
            grid-template-rows: 1fr auto;
        }
    }

    &__block {
        margin: 0;
        display: grid;
        grid-template-rows: 1fr auto;
        break-inside: avoid;
        // margin-top: 32px;
        // width: 49%;
        // box-sizing: border-box;
        display: flex;
        flex-direction: column;
        transition: all 0.5s;
        &.active{
            transition: all 0.5s;
            .answer__block-header{
                background-color: $background;
                box-shadow: none;
            }
            .answer__block-info{
                position: static;
                top: 0;
                opacity: 1 !important;
                visibility: visible;
                z-index: 1;
                // display: block;
            }
            .plus__block{
                transition: all 0.5s;
                transform: rotate(180deg);
                background-color: $red;
                svg{
                    path{
                        fill: $white;
                        stroke:$white;
                    }
                }
            }
        }
    }


    // .form__block-header

    &__block-header {
        background-color: $white;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
        padding: 16px 32px;
        display: flex;
        justify-content: space-between;
        gap: 30px;
        align-items: center;
        transition: all 0.5s;
        &:hover{
            transition: all 0.5s;
            transform: scale(1.01);
            box-shadow: 0px 30px 30px rgba(44, 68, 142, 0.06);
        }
        @media(max-width:$md3){
            gap: 15px;
        }
    }

    // .form__block-number


    // .form__block-title

    &__block-title {
        font-weight: 400;
        line-height: 150% !important;
        transition: all 0.5s;
        // max-width: 544px;

    }

    // .form__block-info

    &__block-info {
        // display: none;
        position: absolute;
        z-index: -1;
        top: -100%;
        opacity: 0;
        visibility: hidden;
        font-size: 16px !important;
        line-height: 150% !important;
        padding: 16px 32px;
        // color: #FFFFFF;
        transition: all 0.5s;

    }

    .plus__block{
        cursor: pointer;
        transition: all 0.5s;
        min-width: 60px;
        min-height: 60px;
        max-height: 60px;
        max-width: 60px;
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: $background;
        &:hover{
            transition: all 0.5s;
            transform: scale(0.85);
        }
        @media(max-width:$md4){
            min-width: 52px;
            min-height: 52px;
            max-height: 52px;
            max-width: 52px;
            height: 52px;
            width: 52px;
        }
    }

}
.container {
}


.containert {
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;
    /* Your container needs a fixed height, and it
     * needs to be taller than your tallest column. */
    height: 1000px;

    /* Optional */
    background-color: #f7f7f7;
    border-radius: 3px;
    padding: 20px;
    width: 60%;
    margin: 40px auto;
    counter-reset: items;
  }

  .item {
    width: 49%;
    /* Optional */
    position: relative;
    margin-bottom: 2%;
    border-radius: 3px;
    background-color: #a1cbfa;
    border: 1px solid #4290e2;
    box-shadow: 0 2px 2px rgba(0,90,250,0.05),
      0 4px 4px rgba(0,90,250,0.05),
      0 8px 8px rgba(0,90,250,0.05),
      0 16px 16px rgba(0,90,250,0.05);
    color: #fff;
    padding: 15px;
    box-sizing: border-box;
  }

  .item::before {
    counter-increment: items;
    content: counter(items);
  }

  /* Re-order items into 3 rows */
  .item:nth-child(odd) { order: 1; }
  .item:nth-child(even) { order: 2; }


















