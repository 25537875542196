.soc {


    &__container{
        position: relative;
        display: flex;
        justify-content: end;
    }
    // .soc__block

    &__block {
        z-index: 2;
        position: fixed;
        bottom: 100px;
        right: 40px;
        display: flex;
        flex-direction: column-reverse;
        gap: 16px;
        align-items: center;
        @media(max-width:$md2){
            bottom: calc(10% - 4px);
        }
    }

    // .soc__init

    &__init {
        width: 62px;
        height: 62px;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 16px;
        border-radius: 100%;
        background-color: $red;
        box-shadow: 0px 0px 35px rgba(44, 68, 142, 0.15);
        transition: 0.3s;
        svg{
            path{
                stroke: $white;
            }
        }
        &:hover{
            transform: scale(1.1);
        }
        @media(max-width:$md2){
            width: 57px;
            height: 57px;
        }
    }

    // .soc__init-icon

    &__init-icon {
    }

    // .soc__close-svg

    &__close-svg {
        transition: 0.3s;
        display: none;
    }

    // .soc__init-svg

    &__init-svg {
        transition: 0.3s;
    }

    // .soc__list

    &__list {
        display: none;
        flex-direction: column;
        gap: 8px;
        height: max-content ;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
        position: relative;
        z-index: -5;
    }

    // .soc__list-item

    &__list-item {
        height: 58px;
        width: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: $background;
        box-shadow: 0px 0px 35px rgba(44, 68, 142, 0.15);
        padding: 13px;
        transition: 0.3s;
        &:hover{
            transform: scale(1.1);
        }

    }

    // .soc__link

    &__link {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size:0px ;
    }
}


.viber{
    background: #6E65EA;
}

.telegram{
    background-color: #4597D6;
}

.soc_activ{
    .soc__init-svg{
        display: none;
    }
    .soc__close-svg{
        display: flex;
        path{
            fill: $white;
        }
    }
    .soc__list{
        height: max-content !important;
        visibility: visible !important;
        opacity: 1 !important;
        display:flex ;
    }

}
.d-none {
    display: none !important;
}
