.documents {

    // .documents__container
    #info_for_patients-replaceCode{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 32px;
        @media(max-width:$md2){
            grid-template-columns: 1fr 1fr;
            justify-items: center;
        }
        @media(max-width:$md3){
            grid-template-columns: 1fr;
        }
    }
    &__container {



        #replaceCode{

        }
    }

    // .documents__cart

    &__cart {
        border-radius: 10px;
        max-width: 385px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 31px;
        padding: 24px;
        background-color: $background;
        @media(max-width:$md3){
            max-width: 500px;
        }
    }

    // .documents__img

    &__img {
        border-radius: 10px;
        max-width: 100%;
        height: 192px;
        img{
            border-radius: 10px;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    // .documents__info

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        gap: 14px;
    }

    // .documents__text

    &__text {
        font-weight: 500;
        font-size: 17px;
        line-height: 23px;
        color: $title;
    }

    &__buttons{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    // .documents__download

    &__download {
        max-width: 100%;
        width: 100%;
    }

    // .documents__watch

    &__watch {
        padding: 16.5px;
        width: 100%;
    }
}

