// for all swipers

.swiper-pagination {
    z-index: 1 !important;
    position: relative;
}

.response {

    &__container{
        @media(max-width:$md3){
            // padding: 0px 35px;
        }
    }
    .swiper {
        animation:fadeEffect .7s;
        &._tab-active{
            display: block;
        }
        @media(max-width:$md3){
            width: calc(100% + 48px);
            left: -24px;
            padding: 22px;
            .swiper-slide{
                opacity: 0;
                &.swiper-slide-active , &.swiper-slide-next{
                    opacity: 1;
                }
            }
        }
        @media(max-width:575px){
            .swiper-slide{
                opacity: 0;
                &.swiper-slide-next{
                    opacity: 0;
                }
            }
        }
        @media(max-width:360px){
            width: calc(100% + 30px);
            left: -15px;
        }
    }
    .swiper-wrapper {
        padding: 40px 0;

    //
        .response__cart {
            .response__cart-block_content {
                display: -webkit-box;
                -webkit-line-clamp: 9;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }
    .swiper-slide {
        height: auto;
    }

    .swiper-pagination{
        display: none !important;
        @media(max-width:$md3){
            display: block !important;
        }
    }

    .reviews_nav {
        display: flex;
        justify-content: flex-end;
        height: 64px;

        @media screen and (max-width: $md3) {
            // display: none;
            position: absolute;
            justify-content: flex-start;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            left: -45px;
            width: calc(100vw + 20px);
            left: -10px;
        }
    }

    &__navigation{
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
        gap: 28px;
        width: max-content;
        @media(max-width:$md3){
            // display: none;
            width: 100%;
            justify-content: space-between;
        }
    }
    .swiper-button-prev , .swiper-button-next {
        top: 0 !important;
        position: relative!important;
        background: $background;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        max-width: 64px;
        max-height: 64px;
        min-width: 64px;
        min-height:64px ;
        width: 100% !important ;
        height: 100% !important;
        border-radius: 100%;
        margin-top: 0 !important;
        transition: 0.2s;
        svg{
            width: 16px;
        }
        &:hover{
            background: $light-red;
            transition: 0.2s;
        }
        @media(max-width:$md3){
            max-width: 45px;
            max-height: 45px;
            min-width: 45px;
            min-height:45px ;
        }
    }

    .swiper-button-disabled{
        opacity: 1 !important;
        svg{
            path{
                fill: $subtitle;
            }
        }
    }

    .swiper-button-prev{
        svg{
            rotate: 180deg;
        }
    }

    .swiper-button-prev:after , .swiper-button-next:after{
        content: none !important;
    }

    // .response__container

    &__container {
    }

    // .response__tab

    &__tab {
        transform: translateY(calc(100% + 7px));
        position: relative;
        z-index: 2;
        width: calc(100% - 160px);
        @media screen and (max-width: $md3) {
            transform: translateY(0);
            width: 100%;
        }
    }

    // .response__tab-block

    &__tab-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    // .response__tab-block-content

    &__tab-block-content {
        display: flex;
        gap: 16px;
        align-items: center;

        @media screen and (max-width: $md4) {
            //transform: translateY(0);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            width: 100%;
        }
    }

    // .response__tab-title

    &__tab-title {
        color: $text3;
        padding: 12px 32px;
        border: 1px solid $subtitle;
        max-width: max-content;
        cursor: pointer;
        border-radius: 10px;
        transition: 0.5s;
        // todo : перевірити чи це ще використовується
        &._tab-active{
            border-color: $red;
            color: $red;
        }

        &.active {
            border-color: $red;
            color: $red;
        }

        &:hover{
            transition: 0.5s;
            background-color: $hover-red;
            border-color: $hover-red;
            color: $white;
        }
        @media(max-width:$md4){
            padding: 12px 16px;
            width: 100%;
            display: flex;
            justify-content: center;
            font-size: 12px !important;
            max-width: 100%;
        }
        @media(max-width:360px){
            padding: 12px ;
        }
    }

    // .response__tab-content

    &__tab-content {
    }

    &__cart{
        //min-height: 450px;
        background: #FFFFFF;
        width: 100%;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
        height: 100%;
        justify-content: space-between;
    }

    // .response__video

    // todo: перевірии чи викорисовуються ці стилі

    &__video {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    // todo: перевірии чи викорисовуються ці стилі

    &__iframe{
        border-radius: 10px 10px 0 0;
        height: 384px;
        width: 100%;
        @media(max-width:$md3){
            height: 322px;
        }
    }

    // .response__cart-content

    &__cart-content {
        display: flex;
        flex-direction: column;
        padding: 24px;
    }

    //&__author {
    //    display: flex;
    //    align-items: center;
    //    width: 100%;
    //}

    // .response__cart-info

    &__cart-info {
        max-width: 100%;
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: space-between;
        height: 52px;

        img {
            width: 48px;
            height: 48px;
            &.fb_avatar {
                width: 48px;
                height: 48px;
                min-width: 48px;
                min-height: 48px;
                object-fit: cover;
                border-radius: 99px;
                overflow: hidden;
            }
        }
    }

    // .response__cart-doctor

    &__cart-author {
        color: $title;
        width: 100%;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;

        .center {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }

    // .response__cart-links

    &__cart-links {
        display: flex;
        gap: 12px;
        align-items: flex-start;
    }

    // .response__social

    &__social {
        min-width: 24px !important;
        min-height: 24px !important;
        max-height: 24px !important;
        max-width: 24px !important;
        svg{
            min-width: 24px !important;
            min-height: 24px !important;
            max-height: 24px !important;
            max-width: 24px !important;
        }
    }

    // .response__service

    &__service {
        margin-top: 8px;
        color: $subtitle;
    }

    // .response__cart-content

    &__cart-content-text {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 24px 24px 32px;
        height: 100%;
        justify-content: space-between;

        &_pop-up {
            padding: 0;

            .response__cart-block {
                &_cart {
                    display: none;
                }

                &_pop-up {
                    display: flex;
                }
            }

            .reviews__date {
                justify-content: flex-end;
                .read-more {
                    display: none;
                }
            }



        }
    }

    // .response__cart-block

    &__cart-block {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &_content {
            height: 100%;
        }
        &_items {
            gap: 8px;
        }
        &_pop-up {
            display: none;
        }

        &.reviews-page {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @media screen and (max-width: $md2) {
                flex-direction: column;
                align-items: flex-start;
            }

            .response__cart {
                min-height: 100%;
            }
        }
    }

    // .response__cart-rait

    &__cart-rait {
        display: flex;
        align-items: center;
        gap: 9px;
    }

    // .response__cart-text

    &__cart-text {
        color: $subtitle;
    }


    @keyframes fadeEffect {
        from {opacity: 0;}
        to {opacity: 1;}
    }

}



.blog-swiper__content{
    .swiper{
        // width: calc(100% - 192px);
        // padding: 0px 96px ;

        .articles-swiper-navigation{
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            // width: calc(100% + 192px);
            display: flex;
            justify-content: space-between;
        }
    }
}


// 192px
