.conditions {

    // .conditions__container

    &__container {
        display: flex;
        flex-direction: column;
        gap: 40px;
        @media(max-width:$md2){
            gap: 24px;
        }
    }


    // .conditions__content

    &__content {
        // display: flex;
        // flex-direction: column;
        // gap: 26px;
        max-width: 800px;
        &.content-bloc{
            max-width: 100%;
        }
    }

    // .conditions__title

    &__title {
        max-width: 800px;
        color: $title;
    }

    // .conditions__subtitle

    &__subtitle {
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        color: #272B38;
        margin-top: 14px;
        color: $title;
        @media(max-width:$md4){
            color: $text3;
            margin-top: 6px;
        }
    }

    // .conditions__list

    &__list {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    // .conditions__list-item

    &__list-item {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    // .conditions__list-text

    &__list-text {
        // display: flex;
        display: block;
        margin-bottom: 26px;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: $text3;
        display: flex;
        flex-direction: column;
        gap: 16px;
        ul , ol{
            margin: 4px 0;
            display: flex;
            flex-direction: column;
            gap: 16px;
            li{
                list-style: outside;
                margin-left: 20px;
            }
        }
        h4{
            font-weight: 500;
            font-size: 20px;
            line-height: 27px;
            color: #272B38;
        }
        @media(max-width:$md2){
            margin-bottom: 16px;
            font-size: 14px;
            line-height: 130%;
            h4{
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
}
.container {
}
.h1 {
}
