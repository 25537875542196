.prices {

    // .prices__container

    &__container {
        display: flex;
        flex-direction: column;
        gap: 56px;
        align-items: center;
    }

    // .prices__top

    &__top {
        display: flex;
        flex-direction: column;
        gap: 32px;
        max-width: 800px;
    }

    // .prices__title

    &__title {
        color: $title;
        text-align: center;
    }

    // .prices__text

    &__text {
        text-align: center;
        color: $subtitle;
        font-size: 16px;
        line-height: 150%;
        color: #82879A !important;
        display: flex;
        flex-direction: column;
        gap: 24px;
        ul , ol{
            display: flex;
            flex-direction: column;
            gap: 16px;
            li{
                list-style: outside;
                margin-left: 20px;
            }
        }
        h4{
            font-weight: 500;
            font-size: 20px;
            line-height: 27px;
            color: #272B38;
        }
        @media(max-width:$md2){
            font-size: 14px;
            line-height: 130%;
            h4{
                font-size: 18px;
                line-height: 24px;
            }
        }
    }

    // .prices__content

    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
    }

    &__list-item-description{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__list-description{
        color: $subtitle !important;
        font-size: 14px !important;
        line-height: 130% !important;
        display: flex;
        flex-direction: column;
        gap: 16px;
        ul {
            display: flex;
            flex-direction: column;
            gap: 8px;
            li {
                list-style: inside;
                color: $subtitle !important;
                font-size: 14px !important;
                line-height: 130% !important;
            }
        }
        ol {
            display: flex;
            flex-direction: column;
            gap: 8px;
            li {
                list-style: inside;
                color: $subtitle !important;
                font-size: 14px !important;
                line-height: 130% !important;
            }
        }
        @media(max-width:$md3){
            font-size: 14px !important;
        }
    }

    // .prices__block

    &__block {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        transition: 0.4s;
        &.active{
            transition: 0.4s;
            margin-bottom: -16px;
            .prices__block-header{
                background-color: $background;
                svg{
                    transform: rotate(360deg);
                }
            }
            .prices__block-content{
                display: flex;
                position: relative;
                top: 0;
                opacity: 1;
                visibility: visible;
            }
        }
    }

    // .prices__block-header

    &__block-header {
        transition: 0.4s;
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 20px;
        align-items: center;
        padding: 24px;
        background: #FFFFFF;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
        svg{
            transition: 0.4s;
            transform: rotate(180deg);
        }
        .head-price-name{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            span{
                color: $red;
            }
            @media(max-width:$md3){
                flex-direction: column;
                gap: 8px;
                align-items: flex-start;
            }
        }
    }

    // .prices__block-title

    &__block-title {
        font-weight: 500;
        font-size: 16px !important;
        line-height: 22px;
        color: #272B38;
    }

    &__block-parts{
        display: flex;
        align-items: center;
        gap: 8px;
        .imgs{
            display: flex;
            align-items: center;
            gap: 2px;
            img{
                width: 14px;
                height: 14px;
                object-fit: contain;
            }
        }
    }

    &__block-parts-description{
        display: flex;
        gap: 6px;
        background: #F2F4F8;
        border-radius: 4px;
        align-items: center;
        padding: 3px 6px;
        span{
            color:  $title !important;
            font-size: 14px !important;
            line-height: normal;
        }
    }

    // .prices__block-content

    &__block-content {
        :first-child{
            margin-top: 0 !important;
        }
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: -40px;
        transition: 0.4s;
        padding: 0px 24px 0px 24px;
        width: 100%;
        display: none;
        flex-direction: column;
        align-items: flex-start;
        @media(max-width:$md3){
            padding: 5px;
        }
        .prices-content-description-grey{
            color: #82879A !important;
            font-size: 14px !important;
            line-height: 130% !important;
            margin-bottom: 24px;
            margin-top: -16px;
        }
    }

    // .prices__list

    &__list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }

    // .prices__list-item

    &__list-item {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        gap: 32px;
        padding-bottom: 8px;
        border-bottom: 1px solid rgba(44, 68, 142, 0.25);
        &:last-child{
            border-color: #FFFFFF;
        }
        .prices__block-parts{
            gap: 16px;
        }
        @media(max-width:$md2){
            flex-direction: column;
            gap: 10px;
            .prices__block-parts{
                display: flex;
                width: 100%;
                flex-direction: row-reverse;
                justify-content: space-between;
                .prices__list-with-discounts{
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                }
            }
        }
    }

    // .prices__list-service

    &__list-service {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: $text3;
        @media(max-width:$md3){
            font-size: 14px;
            line-height: 20px;
        }
    }


    &__list-with-discounts{
        display: flex;
        gap: 16px;
        align-items: center;
        @media(max-width:$md3){
            flex-direction: column;
            gap: 0px;
        }
    }
    // .prices__list-price

    &__list-price {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: $title;
        white-space: nowrap;
        &.default{
            font-weight: 400;
            font-size: 14px;
            text-decoration: line-through;
            color: #82879A;
        }
    }

    // .prices__link

    &__link {
        margin: 12px auto 20px auto;
    }

    @keyframes fadeEffect {
        from {opacity: 0;}
        to {opacity: 1;}
    }
}
