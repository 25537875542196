.footer {
    margin-top: 100px;
    background: $background;
    border-radius: 20px 20px 0 0;
    padding: 48px 0;
    @media(max-width:$md3){
        margin-top: 70px;
    }
    // .footer__container

    &__container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
        justify-content: space-between;
        gap: 15px;
        @media(max-width:$md3){
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    // .footer__left

    &__left {
        display: flex;
        flex-direction: column;
        gap: 35px;
        @media(max-width:$md3){
            align-items: center;
        }
    }

    // .footer__logo

    &__logo {
        font-size: 0;
    }

    // .footer__about

    &__about {
        color:$subtitle;
        line-height: 140%;
        @media(max-width:$md3){
            display: none;
        }
    }

    // .footer__mobile-phone

    &__mobile-phone {
        display: flex;
        align-items: center;
    }

    &__contact{
        display: flex;
        flex-direction: column;
        gap: 12px;
        @media(max-width:$md3){
            display: none;
        }
    }

    &__socials{
        display: flex;
        gap: 12px;
        align-items: center;
        font-size: 0;
    }

    &__center{
        display: flex;
        justify-content: end;
        @media(max-width:$md3){
            display: none;
        }
    }

    // .footer__list

    &__list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        li{
            &:nth-child(1){
                margin-bottom: 10px;
            }
        }
        @media(max-width:$md3){
            align-items: center;
        }
    }

    // .footer__list-title

    &__list-title {
        font-size: 16px;
        line-height: 150%;
        color: $title;
        @media(max-width:$md3){
            display: none;
        }
    }

    // .footer__link

    &__link {
        .active {
            line-height: 140%;
            color: $red;
        }
        a{
            line-height: 140%;
            color: $subtitle;
            transition: 0.3s;
            &:hover{
                color: $red;
            }
        }

    }

    // .footer__right

    &__right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: end;
        gap: 20px;
        @media(max-width:$md3){
            align-items: center;
        }
    }

    // .footer__ps

    &__ps {
        display: flex;
        flex-direction: column;
        p{
            text-align: right;
            color: #BDC2D2;
            margin-top: 12px;
            line-height: 140%;
        }
        @media(max-width:$md3){
            a{
                text-align: center;
                justify-content: center;
            }
            p{
                text-align: center;
            }
        }
        .location{
            align-self: flex-end;
            text-align: end;
        }
    }
}
.container {
}
.logo {
}
.location {
}
.socials {
}


