.services {

    // .services__container

    &__container {
        display: flex;
        flex-direction: column;
        gap: 56px;
        align-items: center;
    }

    // .services__title

    &__title {
        color: #272B38;
        margin-bottom: 40px;
        @media(max-width:$md4){
            margin-bottom: 0;
        }
    }

    // .services__content

    &__content {
        display: flex;
        flex-wrap: wrap;
        column-gap: 32px;
        row-gap: 83px;
        justify-content: center;
        @media(max-width:$md3){
            flex-direction: column;
            row-gap: 32px;
        }
    }

    // .services__cart

    &__cart {
        max-width: 280px;
        width: 100%;
        display: flex;
        position: relative;
        transition: 0.2s;
        flex: 1 1 220px;
        @media(max-width:$md3){
            max-width: 100%;
            width: 100%;
            flex: 1 1 auto;
        }

    }

    // .services__cart-img

    &__cart-img {
        position: absolute;
        border-radius: 100%;
        border: 2px solid $red;
        max-width: 110px;
        width: 100%;
        top: -55px;
        // left: 50%;
        // transform: translate(-50%, 0);
        min-width: 110px;
        min-height: 110px;
        max-height: 110px;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100%;
        }
        @media(max-width:$md3){
            position: relative;
            top: 0;
            left: 0;
            transform: translate(0,0);
            max-width: 80px;
            min-width: 80px;
            min-height: 80px;
            max-height: 80px;
            height: 100%;
        }
    }

    // .services__block

    &__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 24px;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 20px;
        padding: 74px 24px 41.5px 24px;
        &:hover{
            box-shadow: 0px 18px 24px rgba(44, 68, 142, 0.1);
            // transition: 0.2s;
            transform: scale(1.03)
        }
        @media(max-width:$md3){
            flex-direction: row;
            padding: 16px;
            width: 100%;
        }
    }

    // .services__text

    &__text {
        text-align: center;
        color: $title;
        width: 100%;
    }

    // .services__link

    &__link {
        padding: 16px 32px;
        @media(max-width:$md3){
            display: none;
        }
    }
}

