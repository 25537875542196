.content-bloc {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #515668;


    ul,ol{
        margin-top:20px;

    }
    p {
        position: relative;
        margin-top: 15px;
        font-weight: 400;
        font-size: 16px  !important;
        line-height: normal;
        color: #515668;
    }


    h1, h2, h3, h4,
    .h1, .h2, .h3, .h4 {
        // font-size: 24px;
        color: $title  !important;
        font-family: Noto Sans  !important;
        font-weight: 600  !important;
        line-height: normal !important;
    }

    .service__right{
        p {
            &.h1, &.h2, &.h3, &.h4 {
                color: $title !important;
                font-weight: 600  !important;
            }

            &.h1 {
                font-size: 38px !important;
                @media(max-width:$md3){
                    font-size: 24px !important;
                }
            }

            &.h2 {
                font-size: 32px !important;
                @media(max-width:$md3){
                    font-size: 20px !important;
                }
            }

            &.h3 {
                font-size: 24px !important;
                @media(max-width:$md3){
                    font-size: 18px !important;
                }
            }

            &.h4 {
                font-size: 18px !important;
                @media(max-width:$md3){
                    // font-weight: 400;
                    font-size: 16px !important;
                }
            }
        }
    }

    h1, .h1 {
        font-size: 38px !important;
        margin-top: 94px !important;
        @media(max-width:$md3){
            font-size: 24px !important;
            margin-top: 56px !important;
        }
    }

    h2, .h2 {
        font-size: 32px !important;
        margin-top: 50px !important;
        margin-bottom: 8px !important;
        &:first-child {
            // margin-top: 0 !important;
        }
        @media(max-width:$md3){
            font-size: 20px !important;
            margin-top: 56px !important;
        }
    }

    h3, .h3 {
        font-size: 24px !important;
        margin-top: 30px !important;
        margin-bottom: 5px !important;
        @media(max-width:$md3){
            font-size: 18px !important;
        }
    }

    h4, .h4 {
        font-size: 18px !important;
        margin-top: 24px !important;
        @media(max-width:$md3){
            // font-weight: 400;
            font-size: 16px !important;
        }
    }

    .prices {
        font-weight: 500;
        &__block {
            p {
                margin-top: 0;
            }
            &-title {
                font-weight: 500;
            }
        }
    }
    .doctors__cart-name{
        margin-top: 0 !important;
    }
    .service__response-cart-author{
        margin-top: 0 !important;
    }
    p.service__block-text.text:first-child {
        margin-top: 0px !important;
    }
    .service__answer-block-title{
        margin-top: 0 !important;
    }
    .service__answer-block-info{
        :first-child{
            margin-top: 0 !important;
        }
    }
    .service__flex-text{

        margin-top: 0 !important;

    }

    :first-child{
        // margin-top: 0 !important;
    }

    .service__response-tab-title{
        margin-top: 0 !important;
    }


    table{
        width: 100%;
        margin-top: 8px;
        border-collapse: collapse;
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;
        text-align: start;
        tr{
            border-bottom: 1px solid $border;
            &:first-child {
                td {
                    padding: 24px 15px;
                    text-align: left;
                    font-weight: 500 !important;
                    font-size: 18px !important;
                    line-height: 150% !important;
                    color: $title !important;
                    &:first-child {
                        border-right: 1px solid $border;
                        padding: 24px 15px 24px 0;
                    }
                    &:last-child {
                        padding-left: 15px;
                    }
                    @media(max-width:$md3){
                        padding: 16px 12px;
                        &:last-child {
                            padding-left: 12px;
                        }
                    }
                }
            }
        }
        th{
            padding: 24px 15px;
            text-align: left;
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
            color: $title;
            &:first-child {
                border-right: 1px solid $border;
                padding: 24px 15px  24px 0;
            }
            &:last-child {
                padding-left: 15px;
            }
            @media(max-width:$md3){
                padding: 16px 12px;
                &:last-child {
                    padding-left: 12px;
                }
            }
        }
        td{
            width: auto;
            border-right: 1px solid $border;
            padding: 24px 15px;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: $subtitle;
            text-align: start;
            vertical-align: top;
            @media(max-width:$md3){
                padding: 16px 12px 16px 0;
            }

            &:first-child {
                padding: 24px 15px  24px 0;
            }


            &:last-child {
                border-right-color: transparent;
                padding: 24px 15px;
            }
        }
    }
    .prices__block{
        margin-top: 32px;
        p{
            margin-top:  0px ;
        }
    }
    .publication-blog__info{
        margin-top: 16px;
    }
    .publication-blog__author{
        margin-top: 40px!important;
        margin-bottom: 56px;
        @media(max-width:$md3){
            margin-bottom: 32px;
        }
    }
    .publication-blog__author-link-text{
        margin-top: 0;
    }
    .publication-blog__author-description{
        margin-top: 0;
    }
    .service__block-list-with-img {
        :first-child{
            margin-top: 0 !important;
        }

        margin-top: 32px;
        display: flex;
        gap: 32px;
        ul{
            width: 100%;
        }
        @media(max-width:$md3){
            flex-direction: column-reverse;
        }
    }

    // .service__block-img-with-list

    .service__block-img-with-list {
        display: flex;
        height: 388px;
        max-width: 482px;
        min-width: 340px;
        width: 100%;
        border-radius: 10px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
        @media(max-width:$md3){
            height: 336px;
            max-width: 100%;
            min-width: 100px;
        }
        @media(max-width:$md4){
            height: 228px;
        }
    }

    .publication__buttons {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        gap: 16px;
        .prev{
            svg{
                rotate:180deg;
            }
        }
        @media(max-width:$md3){
            display: flex;
            flex-direction: column-reverse;
            width: 100%;
            align-items: center;
        }
    }


    .publication-blog__buttons {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        gap: 16px;
        .prev{
            svg{
                rotate:180deg;
            }
        }
        @media(max-width:$md3){
            display: flex;
            flex-direction: column-reverse;
            width: 100%;
            align-items: center;
        }
    }
    // .publication__button

    .publication__button {
        width: max-content;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 16px 40px;
        border: 1px solid #BDC2D2;
        border-radius: 10px;
        color: $title;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        max-width: 320px;
        width: 100%;
        justify-content: center;
        transition: .3s;
        @media(max-width:$md3){
            max-width: 400px;
        }
        svg{
            transition: 0.3s;
        }
        &:hover{
            background-color: $hover-red;
            border-color: $hover-red;
            color: $white;
            svg{
                path{
                    stroke: $white;
                }
            }
        }
    }
    .service__answer-block-title{
        color: #272B38 !important;
        font-size: 18px !important;
        line-height: 150% !important;
        font-weight: 400 !important;
    }

    .publication-blog__button {
        width: max-content;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 16px 40px;
        border: 1px solid #BDC2D2;
        border-radius: 10px;
        color: $title;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        max-width: 320px;
        width: 100%;
        justify-content: center;
        transition: .3s;
        @media(max-width:$md3){
            max-width: 400px;
        }
        svg{
            transition: 0.3s;
        }
        &:hover{
            background-color: $hover-red;
            border-color: $hover-red;
            color: $white;
            svg{
                path{
                    stroke: $white;
                }
            }
        }
    }


    .publication-blog__videos{
        margin: 16px 0;
    }
    .publication-blog__video{
        max-width: initial;
    }
    .disabled{
        cursor:not-allowed;
        border-color: $border !important;
        color: #BDC2D2 !important;
        svg{
            path{
                stroke: #BDC2D2 !important;
            }
        }
        &:hover{
            color:#BDC2D2  !important;
            svg{
                path{
                    stroke: #BDC2D2 !important;
                }
            }
            background-color: transparent !important;

        }
    }

    // .service__answer-block
    .arrow__block{
        cursor: pointer;
        transition: all 0.5s;
        min-width: 60px;
        min-height: 60px;
        max-height: 60px;
        max-width: 60px;
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: $background;
        &:hover{
            transition: all 0.5s;
            transform: scale(0.85);
        }
        @media(max-width:$md4){
            min-width: 52px;
            min-height: 52px;
            max-height: 52px;
            max-width: 52px;
            height: 52px;
            width: 52px;
        }
    }
    
    .btn{
        margin-top: 32px !important;
        max-width: 280px;
        width: 100%;
        display: flex;
        gap: 15px;
        align-items: center;
        justify-content: center;
        padding: 16px 30px;
        background: $red;
        border-radius: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        // border: 2px solid $red;
        color: $white;
        transition: .5s;
        &:hover{
            background-color: $hover-red;
            // color: $red;
            transition: .5s;
        }
        @media(max-width:$md4){
            max-width: 350px;
        }
        @media(max-width:360px){
            padding: 10px 20px !important;
        }
    }
    .service__buttons{
        .btn{
            margin-top: 0 !important;
        }
    }
    .service__answer-block {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        transition: all 0.5s;
        &.active{
            transition: all 0.5s;
            .service__answer-block-header{
                background-color: $background;
                box-shadow: none;
            }
            .service__answer-block-info{
                position: static;
                top: 0;
                opacity: 1;
                visibility: visible;
                z-index: 1;
                // display: block;
            }
            .arrow__block{
                transition: all 0.5s;
                transform: rotate(180deg);
                background-color: $red;
                svg{
                    path{
                        fill: $white;
                        stroke:$white;
                    }
                }
            }
        }
        // &:nth-last-child(1){
        //     margin-bottom: 100px;
        // }
    }

//    todo: це имчасовий костиль який треба буде виправити
    p.response__tab-title {
        margin-top: 0 !important;
        @media screen and (max-width: $md2) {
            font-size: 12px !important;
        }
    }
    div{
        .actions{
            .actions__container{
                padding: 20px 0;
                .actions__content-desktop{
                    width: 100%;
                    // display: flex;
                    // flex-direction: column;
                    .action-prew__cart{
                        display: flex;
                        flex-direction: column;
                        .action-prew__cart-description{
                            color: #272B38 !important;
                        }
                        .action-prew__cart-left{
                            max-width: 100%;
                            height: 246px;
                            img{
                                border-radius: 10px 10px 0 0;
                            }
                        }
                    }
                    @media(max-width:$md2){
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        align-items: flex-start;
                    }
                    @media(max-width:575px){
                        grid-template-columns: 1fr;
                    }
                }
            }
        }

    }


}


.advantages__container{
    .btn{
        margin-top: -50px;
        @media(max-width:$md2){
            margin-top: -20px;
        }
    }
}



.noneAnchor{
    @media(max-width:1024px){
        display: none !important;
    }
}


.with-custom-fields{
    p{
        margin-top: 14px;
    }
    color: $subtitle;
    &:first-child{
        // margin-top: 0 !important;
    }
    ul{
        display: flex;
        flex-direction: column;
        gap: 20px;
        li{
            margin-left: 20px;
            list-style: outside !important;
            &::marker{
                border-radius: 100%;
                color: #811916;
            }
        }
    }
    .checkbox{
        li{
            list-style: none !important;
        }
    }
    .decline{
        li{
            list-style: none !important;
        }
    }
    ul, ol{
        margin-top: 14px;
        gap: 20px !important;
    }
    h1, h2, h3, h4,
    .h1, .h2, .h3, .h4 {
        // font-size: 24px;
        color: $title  !important;
        font-family: Noto Sans  !important;
        font-weight: 600  !important;
        line-height: normal !important;
    }
    h1, .h1 {
        font-size: 38px !important;
        margin-top: 94px !important;
        @media(max-width:$md3){
            font-size: 24px !important;
            margin-top: 56px !important;
        }
    }

    h2, .h2 {
        font-size: 32px !important;
        margin-top: 94px !important;
        margin-bottom: 20px !important;
        &:first-child {
            // margin-top: 0 !important;
        }
        @media(max-width:$md3){
            font-size: 20px !important;
            margin-top: 56px !important;
        }
    }

    h3, .h3 {
        font-size: 24px !important;
        margin-top: 30px !important;
        margin-bottom: 5px !important;
        @media(max-width:$md3){
            font-size: 18px !important;
        }
    }

    h4, .h4 {
        font-size: 18px !important;
        margin-top: 24px !important;
        @media(max-width:$md3){
            // font-weight: 400;
            font-size: 16px !important;
        }
    }
}


.prices__list-description{
    color: $subtitle !important;
    font-size: 14px !important;
    line-height: 130% !important;
    display: flex;
    flex-direction: column;
    gap: 16px;
    ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
        li {
            list-style: inside;
            color: $subtitle !important;
            font-size: 14px !important;
            line-height: 130% !important;
        }
    }
    ol {
        display: flex;
        flex-direction: column;
        gap: 8px;
        li {
            list-style: inside;
            color: $subtitle !important;
            font-size: 14px !important;
            line-height: 130% !important;
        }
    }
    @media(max-width:$md3){
        font-size: 14px !important;
    }
}


