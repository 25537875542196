.main-about{
    .about__container{
        margin-top: 0 !important;
    }
    .doctors__link {
        margin-top: -50px;
        @media(max-width:$md2){
            margin-top: -20px;
        }
    }
    .swiper-services{
        .doctors__link {
            margin-top: 0px;
        }

    }
}

.about {

    // .about__container

    &__container {
        display: flex;
        flex-direction: column;
        gap: 56px;
        align-items: center;
        @media(max-width:$md3){
            gap: 40px;
        }
        @media(max-width:$md2){
            margin-top: -56px;
        }
    }

    // .about__top

    &__top {
        max-width: 800px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
    }

    // .about__title

    &__title {
        color: $title;
    }

    // .about__text

    &__text {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 24px;
        font-size: 16px;
        line-height: 150%;
        color: $subtitle;
        ul , ol{
            display: flex;
            flex-direction: column;
            gap: 16px;
            li{
                list-style: inside;
            }
        }
        h4{
            font-weight: 500;
            font-size: 20px;
            line-height: 27px;
            color: #272B38;
        }
        @media(max-width:$md2){
            font-size: 14px;
            line-height: 130%;
            h4{
                font-size: 18px;
                line-height: 24px;
            }
        }
        @media(max-width:$md4){
            text-align: left;
        }
    }

    // .about__middle

    &__middle {
        display: flex;
        justify-content: space-between;
        gap: 24px;
        width: 100%;
        @media(max-width:$md3){
            flex-direction: column;
        }
    }

    // .about__img

    &__img {
        max-height: 296px;
        max-width: 696px;
        width: 100%;
        border-radius: 10px;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
        @media(max-width:$md3){
            max-height: 210px;
            img{
                max-height: 210px;
            }
        }
    }

    // .about__info

    &__info {
        max-width: 488px;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        // grid-template-rows: 1fr 1fr;
        gap: 32px;
        @media(max-width:$md2){
            gap: 24px;
        }
        @media(max-width:$md3){
            max-width: 100%;
            justify-items: center;
        }
    }

    // .about__info-block

    &__info-block {

        max-width: 230px;
        width: 100%;
        // max-height: 127px;

        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
        align-items: center;
        background: $background;
        border-radius: 10px;
        @media(min-width:$md3){
            height: 100%;
        }
        @media(max-width:$md3){
            max-width: 100%;
        }
        @media(max-width:360px){
            padding: 10px;
        }
    }

    // .about__info-block-title

    &__info-block-title {
        word-break: break-all;
        color: $red;
        @media(max-width:$md3){
            font-size: 24px !important;
        }
    }

    // .about__info-block-subtitle

    &__info-block-subtitle {
        font-size: 18px;
        line-height: 150%;
        text-align: center;
        color: $title;
        word-break: break-word;
    }

    // .about__bottom

    &__bottom {
        display: flex;
        flex-direction: column;
        max-width: 800px;
    }

    // .about__btn

    &__btn {
        margin: auto;
        margin-top: 58px;
    }
}
