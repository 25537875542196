.steps {

    // .steps__container

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 58px;
    }

    // .steps__title

    &__title {
        text-align: center;
        color: $title;
    }

    // .steps__content

    &__content {
        width: 100%;
        position: relative;
        display: flex;
        @media(max-width:$md2){
            gap: 24px;
        }
    }

    // .steps__fill

    &__fill {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        height: 100%;
        @media(max-width:$md2){
            position: relative;
            left: 0;
            transform: translate(0, 0);
            height: auto;
        }
    }

    // .steps__svgs

    &__svgs {
        position: absolute;
        width: 5px;
        height: 100%;
        background: linear-gradient(to bottom, #AB332F 0%, #AB332F 0%, #E3E6EE 0%, #E3E6EE 100%);
        transition: .3s;
        border-radius: 5px;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
    }

    // .steps__numbers

    &__numbers {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-around;
    }

    // .steps__number-block

    &__number-block {
        width: 77px;
        height: 77px;
        border-radius: 100%;
        padding: 7px;
        background-color: $white;
    }

    // .steps__number

    &__number {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: $title;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        @media(max-width:$md2){
            font-weight: 700;
            font-size: 16px;
        }
    }

    // .steps__rows

    &__rows {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    // .steps__row

    &__row {
        display: flex;
        gap: 240px;
        align-items: center;
        &:nth-child(2){
            flex-direction: row-reverse;
        }
        @media(max-width:$md2){
            gap: 16px;
            justify-content: space-between;
        }
        @media(max-width:$md3){
            flex-direction: column;
            &:nth-child(2){
                flex-direction: column;
            }
        }
    }

    // .steps__row-img

    &__row-img {
        height: 282px;
        max-width: 488px;
        width: 100%;
        border-radius: 10px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
        @media(max-width:$md2){
            height: 192px;
        }
    }

    // .steps__row-block

    &__row-block {
        max-width: 410px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        @media(max-width:$md3){
            max-width: 100%;
        }
    }

    // .steps__row-title

    &__row-title {
        color: $title;
    }

    // .steps__row-info

    &__row-info {
        color: $text3;
    }
}
