.news {

    // .news__container

    &__container {
        display: flex;
        flex-direction: column;
        gap: 56px;
    }

    // .news__description

    &__description {
        margin: 0 auto;
        text-align: center;
        max-width: 800px;
        color: $subtitle;
    }

    // .news__content

    &__content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 32px;
        @media(max-width:$md2){
            grid-template-columns: 1fr 1fr;
            justify-items: center;
        }
        @media(max-width:$md3){
            grid-template-columns: 1fr;
        }
    }

    // .news__cart

    &__cart {
        max-width: 384px;
        width: 100%;
        display: flex;
        flex-direction: column;
        background: $white;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
        @media(max-width:$md2){
            max-width: 415px;
        }
    }



    // .news__cart-img

    &__cart-img {
        width: 100%;
        max-height: 223px;
        border-radius: 10px 10px 0 0;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 10px 10px 0 0;
        }
    }


    &__cart-padding{
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        height: 100%;
        justify-content: space-between;
    }

    // .news__cart-text

    &__cart-text {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    // .news__cart-title

    &__cart-title {
        color: $title;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        @media(max-width:$md3){
            font-size: 16px;
            line-height: 22px;
        }
    }

    // .news__cart-info

    &__cart-info {
        color: $subtitle;
        line-height: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    // .news__cart-link

    &__cart-link {
        width: max-content;
        padding: 16px 32px;
        color: $red;
        border-radius: 10px;
    }

    &__new-carts{
        margin: 0 auto;
        padding: 16px 32px;
        border-radius: 10px;
        border: 1px solid $border;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        color: $title;
    }
}

