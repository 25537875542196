.news-swiper {

    // .news-swiper__container



    &__container {

    }
    &__title{
        width: 100%;
        text-align: center;
    }
    &__content{
        position: relative;
        z-index: 1;
        display: flex;
        gap: 40px;
        width: 100%;
        align-items: center;
        margin-top: 56px;
        @media(max-width:$md2){
            margin-top: 34px;
        }
    }

    // .news-swiper__cart

    &__cart {
        display: flex;
        flex-direction: column;
        max-width: 488px;
        width: 100%;
        border-radius: 10px;
        background-color: $background;
        height: 100%;

    }

    // .news-swiper__cart-img

    &__cart-img {
        width: 100%;
        height: 223px;
        img{
            width: 100%;
            height: 223px;
            object-fit: cover;
            border-radius: 10px 10px 0 0;
        }
    }

    // .news-swiper__cart-padding

    &__cart-padding {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100%;
        justify-content: space-between;
    }

    // .news-swiper__cart-title

    &__cart-title {
        color: $title;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        @media(max-width:$md3){
            font-size: 16px;
            line-height: 22px;
        }
    }

    // .news-swiper__cart-info

    &__cart-info {
        word-break: break-all;
        color: $subtitle;
        line-height: 22px;
        @media(max-width: $md3){
            line-height: 19px;
        }
    }

    .swiper{
        max-width: 1010px !important;
        width: 100% !important;
        .swiper-slide{
            height: auto;
        }
    }

    .swiper-button-prev{
        position: relative !important;
        min-width: 64px;
        max-width: 64px;
        min-height: 64px;
        max-height: 64px;
        width: 64px !important;
        height: 64px !important;
        border-radius: 100%;
        background-color: $background;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            rotate: 180deg;
        }
        &::after{
            content: none;
        }
        @media(max-width:$md2){
            display: none;
        }
    }

    .swiper-button-next{
        position: relative !important;
        min-width: 64px;
        max-width: 64px;
        min-height: 64px;
        max-height: 64px;
        width: 64px !important;
        height: 64px !important;
        border-radius: 100%;
        background-color: $background;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after{
            content: none;
        }
        @media(max-width:$md2){
            display: none;
        }
    }
    .swiper-pagination{
        position: relative;
        margin-top: 40px;
    }
}
