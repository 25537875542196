.visit {

    // .profile__container

    &__container {
    }

    // .profile__tab

    &__tab {
    }


    // .profile__tab-block-content

    &__tab-block-content {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        align-items: center;
        margin-bottom: 56px;
        @media(max-width:$md2){
            justify-content: flex-start;
            margin-bottom: 33px;
        }
    }

    // .profile__tab-title



    // .visit__tab-block

    &__tab-block {
        display: flex;
        padding: 14px;
        align-items: center;
        justify-content: center;
        border: 1px solid $text3;
        max-width: 292px;
        width: 100%;
        cursor: pointer;
        border-radius: 10px;
        gap: 5px;
        transition: 0.3s;
        &:hover{
            background-color: $red;
            transition: 0.3s;
            border-color: $red;
            .visit__tab-title{
                color: $white;
                transition: 0.3s;
            }
        }
        &._tab-active{
            border-color: $red;
            .for-mobile{
                color: $title !important;
            }
            &:hover{
                background-color: transparent;
                transition: 0.3s;
                border-color: $red;
                .visit__tab-title{
                    color:$text3;
                    transition: 0.3s;
                }
            }
        }
        @media(max-width:$md2){
            align-items: flex-start;
            justify-content: flex-start;
            border: none;
            border-radius: 0;
            max-width: max-content;
            padding: 1px;
            &._tab-active{
                border-color: $red;
                border-bottom: 2px solid $red;
                .visit__tab-title{
                    color: $title;
                }

                .visit__tab-count{
                    display: flex;
                }

            }

        }
    }

    // .visit__tab-title

    &__tab-title {
        transition: 0.3s;
        white-space: nowrap;
        color: $text3;
        @media(max-width:$md2){
            color: $red;
        }
    }

    // .visit__tab-count

    &__tab-count {
        display: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $title;
    }



    // .profile__tab-content

    &__tab-content {
    }


    &__about{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }
    &__about-text{
        color: $text3;
        max-width: 800px;
        text-align: center;
    }
    // .profile__table

    &__table {
        width: 100%;
        display: none;
        .mobile{
            display: none;
        }
        &._tab-active{
            display: table;
        }
        @media(max-width:$md3){
            .none{
                display: none;
            }
            .mobile{
                display: flex;
                background-color: transparent !important;
                text-align: left;
                padding: 16px 0px;
            }
        }

    }

    // .profile__table-row

    &__table-row {

        @media(max-width:$md3){
            display: flex;
            flex-direction: column;
            max-width: 100vw;
            width: 100%;
            background: $white;
            box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
            border-radius: 10px;
            padding: 16px 12px 16px 16px;
        }
    }

    // .profile__table-header

    &__table-header {

    }

    // .profile__table-title

    &__table-title {
        color: $subtitle;
    }

    // .profile__table-body

    &__table-body {
    }
    &__row-space{
        height: 24.5px;

    }

    &__column-space{
        max-width: 49px;
        min-width: 15px;
        // width: 100%;
    }

    // .profile__table-block

    &__table-block {
        max-width: max-content;
        // width: 100%;
        border-radius: 10px;
        background-color: $background;
        padding: 15px;
        text-align: center;
        // display: flex;
        align-items: center;
        // width: max-content;
        // width: max-content;
        @media(max-width:$md2){
            padding: 10px;
        }
        @media(max-width:$md3){
            min-width: 100px;
            padding: 16px;
        }
    }

    // .profile__table-text

    &__table-text {
        color: $title;
        font-weight: 500;
    }

    // .profile__table-link

    &__table-link {
        display: flex;
        gap: 15px;
        align-items: center;
        width: 100%;
        justify-content: center;
        background-color: transparent;
        transition: 0.3s;
        &:hover{
            .visit__table-text{
                color: $red;
                transition: 0.3s;
            }
        }
    }

    // .profile__table-img-doctor

    &__table-img-doctor {
        max-width: 39px;
        max-height: 39px;
        min-width: 39px;
        min-height: 39px;
        height: 100%;
        width: 100%;
        border-radius: 100%;
        img{
            min-width: 39px;
            min-height: 39px;
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 100%;
        }
    }

    // .profile__table-download

    &__table-download {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
        &:hover{
            .visit__table-text{
                color: $red;
                transition: 0.3s;
            }
            svg{
                path{
                    fill: $red;
                }
            }
        }
        @media(max-width:$md3){
            gap:90px ;
        }
    }

    &__mobile-services{
        display: flex;
        gap: 10px;
        svg{
            margin-top: 5px;
            min-width: 20px;
        }
    }


    // .profile__row-data-mobile

    &__row-data-mobile {
        display: none;
        @media(max-width:$md3){
            display: flex;
            width: 100%;
        }
    }

    // .profile__block-data-mobile

    &__block-data-mobile {
        width: 100%;
        text-align: center;
    }

    // .profile__text-data-mobile

    &__text-data-mobile {
        font-size: 14px;
        line-height: 150%;
        color: $subtitle;
    }

    .none-mobile{
        @media(max-width:$md2){
            display: none;
        }
    }

    .for-mobile{
        display: none;
        @media(max-width:$md2){
            display: flex;
        }
    }

    .another-page{
        display: table !important;
    }

    .oplata{
        svg{
            display: none;
        }
        @media(max-width:$md3){
            svg{
                display: block;
            }
            background-color: transparent !important;
            display: flex;
            gap: 8px;
            align-items: center;
            padding: 16px 0px;
        }
    }

    .price{
        @media(max-width:$md3){
            background-color: transparent;
            padding: 16px 0px;
            .visit__table-text{
                color: $red;
                font-weight: 500;
                font-size: 20px;
                line-height: 27px;
            }
        }

    }

}
.container {
}
.text {
}

.red{
    color: $red !important;
}
