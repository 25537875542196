.equipments {
    margin-top: 48px;
    // .equipments__container

    &__container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 32px;
        @media(max-width:$md3){
            grid-template-columns: 1fr;
            justify-items: center;
        }
    }
    #replaceCode{

    }
    &__filters{
        padding-bottom: 36px;
        @media(max-width:768px){
            .doctors-main__filters{
                // position: static;
                // padding: 0;
                // .set-filter{
                //     display: none;
                // }
                // .doctors-main__filters-close{
                //     display: none;
                // }
                .doctors-main__filters-content{
                    justify-content: space-between;
                }
            }
        }
    }

    // .equipments__cart

    &__cart {
        padding: 24px;
        display: flex;
        flex-direction: column;
        max-width: 600px;
        width: 100%;
        border-radius: 10px;
        background-color: $background;
    }

    // .equipments__cart-top

    &__cart-top {
        display: flex;
        max-width: 550px;
        max-height: 525px;
        height: 100%;
        width: 100%;
        margin-top: 0 auto;
        position: relative;
    }

    // .equipments__cart-img

    &__cart-img {
        max-width: 550px;
        max-height: 525px;
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 10px;
        img{
            max-width: 550px;
            max-height: 525px;
            border-radius: 10px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @media(max-width:$md4){
            // max-width: 300px;
            max-height: 220px;
            img{
                // max-width: 300px;
                max-height: 220px;
            }
        }
    }

    // .equipments__cart-info

    &__cart-info {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 1 ;
        gap: 8px;
        left: 24px;
        bottom: 24px;
        @media(max-width:$md4){
            left: 8px;
            bottom: 8px;
        }
    }

    // .equipments__cart-mini-block

    &__cart-mini-block {
        display: flex;
        align-items: center;
        width: max-content;
        gap: 8px;
        padding: 10px 19px;
        @media(max-width:$md4){
            padding: 10px;
        }
    }

    // .equipments__cart-mini-text

    &__cart-mini-text {
        color: $red;
    }

    // .equipments__cart-title

    &__cart-title {
        margin-top: 48px;
        font-weight: 600;
        @media(max-width:$md3){
            margin-top: 24px;
            font-size: 16px;
        }
    }

    // .equipments__cart-text

    &__cart-text {
    }
}
.container {
}
