.main2 {
    position: relative;
    min-height: auto;
    height: 100%;
    // .main2__img

    &__img {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;

        img{

            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // .main2__background

    &__background {
        position: absolute;
        width: 56%;
        height: 100%;
        z-index: 0;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 0px 100px 0px 0px;
        left: 0;
        top: 0;
        @media(max-width:$md2){
            width: 100%;
            border-radius: 0px;
        }
    }

    // .main2__container

    &__container {
        height: 100%;
    }

    &__width{
        height: 100%;
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: space-between;
        max-width: 48%;
        flex-direction: column;
        gap: 20px;
        padding: 65px 0px;
        @media(max-width:$md2){
            max-width: 100%;
        }
    }

    // .main2__price

    &__price {
        font-weight: 500;
        font-size: 20px;
        line-height: 130%;
        padding: 16px 32px;
        color: $red;
        border-radius: 10px;
        max-width: max-content;
        @media(max-width:$md3){
            font-size: 16px;
        }
    }

    // .main2__content

    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    // .main2__title

    &__title {
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
        color: $title;
        @media(max-width:$md3){
            font-size: 20px;
            line-height: 27px;
        }
    }

    // .main2__info

    &__info {
        display: flex;
        flex-direction: column;
        gap: 16px;
        font-size: 18px;
        line-height: 25px;
        color: $text3;

        @media(max-width:$md3){
            font-size: 16px;
            line-height: 22px;
        }
        ul{
            li{
                margin-left: 20px;
                list-style: outside !important;
                &::marker{
                    border-radius: 100%;
                    color: #811916;
                }
            }
        }
        .checkbox{
            li{
                list-style: none !important;
            }
        }
        .decline{
            li{
                list-style: none !important;
            }
        }
        a{
            color: $red !important;
        }
    }

    // .main2__subtitlte

    &__subtitlte {
        font-size: 18px;
        line-height: 25px;
        color: $text3;

        @media(max-width:$md3){
            font-size: 16px;
            line-height: 22px;
        }
    }

    // .main2__list

    &__list {
        display: flex;
        flex-direction: column;
        gap: 16px;

    }

    // .main2__list-item

    &__list-item {
        font-size: 18px;
        line-height: 25px;
        color: $text3;

        list-style: outside;
        margin-left: 20px;


        &::marker{
            // max-width: 8px;
            // max-height: 8px;
            // min-width: 8px;
            // min-height: 8px;
            // width: 8px;
            // height: 8px;
            border-radius: 100%;
            color: #811916;
        }
        @media(max-width:$md3){
            font-size: 16px;
            line-height: 22px;
        }
    }

    &__flexblock{
        display: flex;
        column-gap: 16px;
        align-items: center;
        text-align: center;
        flex-wrap: wrap;
        @media(max-width:$md2){
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
        }
        @media(min-width:$md2) {
            a {
                min-width: max-content;
            }
        }
    }

    // .main2__link

    &__link {
        margin-top: 16px;
        max-width: max-content;
        @media(max-width:$md2){
            margin-top: 0px;
        }
        @media(max-width:$md4){
            max-width: 100%;
        }
    }
}
.container {
}
.btn-2 {
}
.btn {
}
