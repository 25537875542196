.mission {

    // .mission__container

    &__container {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    // .mission__row

    &__row {
        display: flex;
        height: max-content;
        gap: 32px;
        &:nth-child(2){
            flex-direction: row-reverse;
        }
        @media(max-width:$md2){
            flex-direction: column !important;
        }
    }

    // .mission__block

    &__block {
        max-width: 592px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        min-height: 162px;
        gap: 24px;
        padding: 16px;
        border-radius: 10px;
        background-color: $background;
        svg{
            min-width: 64px;
            width: 100%;
            max-width: 64px;
        }
        @media(max-width:$md2){
            max-width: 100%;
            align-items: flex-start;
        }
    }

    // .mission__info

    &__info {
        display: flex;
        flex-direction: column;
        gap: 16px;
        @media(max-width:$md3){
            gap: 8px;
        }
    }

    // .mission__title

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        color: $title;
        @media(max-width:$md3){
            font-size: 16px;
        }
    }

    // .mission__text

    &__text {
    }

    // .mission__img

    &__img {
        max-width: 592px;
        width: 100%;
        height: 162px;
        border-radius: 10px;
        img{
            border-radius: 10px;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
        @media(max-width:$md2){
            max-width: 100%;
        }
    }
}

