.content-bloc{
    &.static-page{
        h1{
            text-align: center;
            margin-bottom: 40px;
        }
        a{
            color: $red;
        }
        ul{
            margin-left: 16px;
            li{
                margin-top: 8px;
                list-style: outside
            }
        }
        @media(max-width:$md2){
            h1{
                margin-top: 100px;
            }
        }
    }
}
