.doctors-main {

    // .doctors-main__container

    &__container {
        // margin-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    // .doctors-main__filters

    &__filters-init{
        display: none;
        margin: 0 auto;
        max-width: 440px;
        width: 100%;
        padding: 12px 32px ;
        gap: 13px;
        border: 1px solid $border;
        background-color: transparent;
        border-radius: 10px;
        text-align: center;
        color: $text3;
        font-size: 16px;
        @media(max-width:$md3){
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }

    &__filters-init-text{

    }

    &__filters {
        display: flex;
        flex-direction: column;
        transition: 0.5s;
        @media(max-width:$md3){
            align-items: center;
            gap: 24px;
            position: fixed;
            z-index: 2;
            right: -800px;
            max-height: 100vh;
            height: 100%;
            top: 0;
            padding: 100px 0px  0px 0px;
        }
        &.filtersActive{
            right: 0px;
            transition: 0.5s;
            z-index: 999999999999;
            padding: 15px 15px 0px 15px !important;
            width: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            background-color: #FFFFFF;
        }
        &.with-padding{
            padding-top: 150px;
        }
    }

    &__filters-close{
        display: none;
        gap: 16px;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid $border;
        padding-bottom: 16px;
        @media(max-width:$md3){
            display: flex;
            padding-left: 15px;
        }
    }

    // &__filters-content{
    //     display: flex;
    //     gap: 16px;
    //     align-items: center;
    //     flex-wrap: wrap;
    //     padding: 0 15px;
    //     height: 100%;
    //     @media(max-width:$md3){
    //         flex-direction: column;
    //         flex-wrap: nowrap;
    //     }
    // }
    &__filters-content{
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
        width: 100%;
        // flex-wrap: wrap;
        padding: 0 15px;
        height: 100%;
        .set-filter{
            width: calc(100% + 30px);
            margin-left: -15px;
        }
        @media(max-width:$md3){
            gap: 18px;
        }
    }

    &__filters-items{
        display: flex;
        flex-direction: column;
        gap: 18px;
        width: 100%;
        @media(max-width:$md3){
            max-width: 400px;
            .doctors-main__button{
                width: 100%;
            }
        }
    }
    &__filters-item{
        display: flex;
        gap: 16px;
        align-items: center;
        flex-wrap: wrap;
        // padding: 0 15px;
        height: 100%;
        width: 100%;
        @media(max-width:$md3){
            flex-direction: column;
            flex-wrap: nowrap;
        }
    }
    // .doctors-main__button

    &__button {
        width: max-content;
        @media(max-width:$md3){
            max-width: 400px;
            width: calc(100% - 30px);
        }

    }

    // .doctors-main__label

    &__label {
        width: 100%;

        input:checked + .doctors-main__input-text {
            color: $red;
            border-color: $red;
            &:hover{
                background-color: $hover-red;
                border-color: $hover-red;
                color: $white;
            }
            @media(max-width:$md3){
                &:hover{
                    border-color: $red;
                    color: $red;
                    background-color:$white;
                }

            }
        }


    }
    input[type="radio"]:checked+label{
        border-color: $red;
        &:hover{

            background-color: $hover-red;
            border-color: $hover-red;
            color: $white;
        }
        @media(max-width:$md3){
            &:hover{
                border-color: $red;
                color: $red;
                background-color:$white;
            }

        }
    }
    // .doctors-main__input

    &__input {
        position:absolute;
        visibility:hidden;
        height:0px;
        width:0px;

    }

    // .doctors-main__input-text

    &__input-text {
        font-size: 18px;
        line-height: 150%;
        color: $text3;
        padding: 12px 32px;
        border: 1px solid #E3E6EE;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        transition: 0.3s;
        &:hover{
            background-color: $hover-red;
            border-color: $hover-red;
            color: $white;
        }
        @media(max-width:$md3){
            justify-content: center;
        }
    }

    // .doctors-main__content
    #doctors-replaceCode{
        display: flex;
        flex-direction: column;
        gap: 24px;

        @media(max-width:$md2){
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-items: center;
        }
        @media(max-width:$md3){
            grid-template-columns: 1fr;
        }
    }

    &__content {

    }

    // .doctors-main__cart

    &__cart {
        display: flex;
        align-items: center;
        gap: 15px;
        justify-content: space-between;
        padding: 32px;
        background: #FFFFFF;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
        @media(max-width:$md2){
            flex-direction: column;
            gap: 24px;
            max-width: 440px;
        }
        @media(max-width:$md4){
            padding: 24px;
        }
        @media(max-width:360px){
            padding: 12px;
        }
    }

    // .doctors-main__photo

    &__photo {
        max-width: 300px;
        width: 100%;
        max-height: 222px;
        min-width: 280px;
        img{
            max-width: 300px;
            min-width: 280px;
            max-height: 222px;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
        }
        @media(max-width:$md2){
            max-width: 100%;
            max-height: 270px;
            img{
                max-width: 360px;
                max-height: 270px;
            }
        }
        @media(max-width:360px){

            img{
                min-width: 240px;
                max-height: 200px;
            }
        }
    }

    // .doctors-main__info

    &__info {
        max-width: 540px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        a{
            color: $red !important;
        }
    }

    // .doctors-main__title

    &__title {
        color: $title;
    }

    // .doctors-main__subtitle

    &__subtitle {
        color: $subtitle;
        font-size: 16px;
        line-height: 150%;
    }

    // .doctors-main__buttons

    &__buttons {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 250px;
        width: 100%;
        @media(max-width:$md2){
            margin-top: 12px;
            max-width: 100%;
        }
    }

    // .doctors-main__link

    &__link {
        width: 100%;
        padding: 16px 32px;
        @media(max-width:$md2){
            padding: 13px 20px;
        }
    }

    // .doctors-main__popup

    &__popup {
        width: 100%;
        @media(max-width:$md2){
            padding: 13px 20px;
            max-width: 100%;
        }
    }
}


.doctor-main-page{
    .doctors__link {
        margin-top: -50px;
        @media(max-width:$md2){
            margin-top: -20px;
        }
    }
    .swiper-services__content-text{
        color: $text3 !important;
    }
}

