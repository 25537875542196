.modal {
    padding: 100px 15px;
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $blackOpacity;
    justify-items: center;
    // .modal__container
    display: none;
    overflow: scroll;
    font-weight: 300;
    font-size: 16px;

    .close-icon-modal{
        position: absolute;
        top: 35px;
        right: 35px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        &::before{
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 1px ;
            background: $title;
            rotate: 45deg;
        }
        &::after{
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 1px ;
            background: $title;
            rotate: -45deg;
        }
        @media(max-width:$md4){
            top: 25px;
            right: 15px;
        }
    }
    // .modal__form
    
    &__form {
        padding: 32px;
        max-width: 690px;
        min-width: 200px;
        width: 100%;
        height: max-content;
        margin: auto;
        background-color: $white;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        position: relative;
    }

    // .modal__title

    &__title {
        max-width: 500px;
        margin: 0 auto;
        text-align: center;
    }

    // .modal__content

    &__content {
        max-width: 600px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    // .modal__btn

    &__btn {
    }

    // .modal__flex-block
    &__flex-block{
        display: flex;
        width: 100%;
        gap: 16px;
        align-items: flex-start;
        @media(max-width:$md2){
            flex-direction: column;
            
        }
    }
    &__mini-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        &.hidden-piker{
            display: none !important;
        }
        &.hidden{
            display: none !important;
        }
    }

    // .form__input-title

    &__input-title {
        color: $title;
        span{
            font-size: 14px;
            opacity: 0.7;
        }
    }

    // .form__input-block

    &__input-block {
        background-color: $background;
        border-radius: 10px;
        position: relative;
        svg{
            position: absolute;
            left: 28px;
            top: 50%;
            transform: translateY(-50%);


        }
        input{
            &::placeholder {
                color: $subtitle;
            }
        }

        svg{
            fill: $subtitle;
            @media(max-width:$md4){
                left: 12px;
            }
        }

    }

    // .form__input

    &__input {
        padding: 13.5px 24px 13.5px 64px;
        border-radius: 10px;
        // color: $subtitle !important;
        // color: #272B38;
        color: $title !important;
        width: 100%;
        // font-family: 'Avenir';
        font-size: 16px;
        font-weight: 300;
        line-height: 27px;
        background-color: $background;
        @media(max-width:$md4){
            font-size: 16px;
            padding: 12px 15px 12px 45px;
        }
    }

    &__select-mask{
        background-color: $background;
        border-radius: 10px;
        max-height: 54px;
        height: 100%;
        // max-width: 85px;
        display: flex;
        align-items: center;
        width: 100%;
        .modal__select{
            margin-bottom: 0px;
            max-width: 85px;
            width: 100%;
            background-color: transparent;
        }
        .selectDropdown{
            margin-top: -10px;
            box-shadow: none ;
        }
        .selectBtn::after{
            right: 13px;
        }
        .modal__select-option{
            text-align: center;
        }
    }

    // .form__select

    &__select {
        // background-color: $background;
        width: 100%;
    }

    // .form__selectBtn

    &__selectBtn {
        background-color: $background !important;
        padding: 13.5px 24px !important;
        border-radius: 10px !important;
        display: flex;
        color: $subtitle;
        width: 100%;
        // font-family: 'Avenir';
        font-size: 16px;
        line-height: 27px;
        color: $title;

        &[data-type="placeholder"] {
            color: $subtitle;
        }
        @media(max-width:$md4){
            padding-top: 12px !important;
            padding-bottom: 12px !important;
            padding-right: 45px !important;
            padding-left: 15px !important;
            font-size: 16px;
        }

        &.text-ellipsis {
            @media(max-width:$md4){
                text-overflow: ellipsis;
                overflow: hidden;
                text-wrap: nowrap;
            }
        }
    }

    .phone-mask{
        padding: 12px 15px !important;
        &::placeholder {
            color: $subtitle;
        }
    }
    // .form__selectDropdown

    &__selectDropdown {
        width: 100%;
        background-color: $background;
        margin-top: -10px;
    }

    // .form__select-option

    &__select-option {
        background-color: $background;
        color: $subtitle;
        width: 100%;
        // font-family: 'Avenir';
        font-size: 16px;
        line-height: 27px;
    }

    &__textarea {
        resize: none;
        padding: 14px 24px 14px;
        border-radius: 10px;
        color: $title;
        width: 100%;
        // font-family: 'Avenir';
        font-size: 16px;
        font-weight: 300;
        line-height: 27px;
        background-color: $background;
        height: 170px;
        &::placeholder {
            color: $subtitle;
        }
    }


    &__datepicker-block{
        border-radius: 10px;
        width: 100%;
        height: 54px;
        position: relative;
        background-color: $background;
        svg{
            position: absolute;
            top: 50%;
            right: 26px;
            transform: translate(0 , -50%);
        }
    }

    &__datepicker{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background-color: transparent;
        padding: 16px 24px;
        font-size: 16px;
        font-weight: 300;
        z-index: 0;
        cursor: pointer;
        color: $title;
        &::placeholder {
            color: $subtitle;

        }
    }

    &__input-text{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background-color: transparent;
        padding: 16px 24px;
        font-size: 16px;
        font-weight: 300;
        z-index: 0;
        color: $title;
        &::placeholder {
            color: $subtitle;

        }
    }

    &__datepicker-login{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background-color: transparent;
        padding: 16px 24px;
        z-index: 2;
        cursor: pointer;
    }

    
    #recaptcha-container-record{
        max-width: 310px !important;
        width: 100%;
        div{
            max-width: 310px !important;
            width: 100% !important;
            min-width: 200px !important;
        }
        iframe{
            max-width: 310px !important;
            width: 100% !important;
            min-width: 200px !important;

        }
    }

    &__buttons{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 24px;
        width: 100%;
        justify-content: center;
        button , div{
            // flex: 1 1 240px;
            height: 56px !important;
        }
        .modal__btn-close{
            height: auto !important;
            // flex: auto;
            width: max-content;
            margin: 0 auto;
            display: none;
        }
        @media(max-width:$md4){
            display: block;
        }
        // @media(max-width:$md4){
        //     flex-wrap: nowrap;
            
        //     flex-direction: column-reverse;
        //     button , div{
        //         flex: auto;
        //     }
        // }
    }

    &__btn-close{
        // padding: 16px 30px;
        color: $red;
        // max-width: 280px;
        cursor: pointer;
        text-align: center;
        // border: 1px solid $red;
        // background: $white;
        // color: $red;
        // &:hover{
        //     color: $white;
        // }
    }
}

.activeModal{
    display: grid;
}

.qs-datepicker-container{
    padding: 8px !important;
    border: 1px solid $border !important;
    border-radius: 16px;
    background-color: $white !important;
    width: 366px !important;
    @media(max-width:$md4){
        width: 250px !important;
    }
    .qs-controls{
        height: 40px;
        background-color: $white;
        justify-content: center !important;
    }
    .qs-left::after{
        content: url(../../img/arrow-left.svg);
        border-color: transparent !important;
    }
    .qs-arrow{
        &:hover{
            transform: translate(-10px 0);
            background-color: transparent !important;
        }
    }
    .qs-right::after{
        content: url(../../img/arrow-right.svg);
        border-color: transparent !important;
        &:hover{
            background-color: transparent !important;
            transform: translate(10px 0);
        }
    }

    .qs-day{
        padding: 12px !important;
        border-bottom: 1px solid $border !important;
        border-radius: 0px !important;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $title !important;
        &:nth-child(6){
            color: #BDC2D2 !important;
        }
        &:nth-child(7){
            color: #BDC2D2 !important;
        }
    }
    .qs-num{
        height: 48px !important;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        border-radius: 24px;
        color: $title !important;
        transition: 0.3s;
        &:hover{
            background-color: $red !important;
            color: $white !important;
        }
        @media(max-width:$md4){
            height: 33px !important;
        }
    }
    .qs-current{
        background: #E3E6EE;
        text-decoration: none !important;
    }
    .qs-active{
        background: #D59997;
        color: $white !important;
    }
    .qs-month{
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        color: $title;
        &:hover{
            text-decoration: none;
        }
    }
    .qs-year{
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        color: $title;
        font-feature-settings: 'liga' off;
    }
    .qs-month-year:not(.qs-disabled-year-overlay):hover {
        border-bottom: 0px transparent !important;
    }

}

