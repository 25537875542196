



.main {
    position: relative;
    height: 348px;
    @media(max-width:$md2){
        height: 263px;
    }
    @media(max-width:360px){
        height: 293px;
    }

    &__block-title{
        padding: 48px 40px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 10px;
        max-width: 740px;
        width: 100%;
        text-align: center;
    }

    &__content-subtitle{
        color: $subtitle;
        margin-top: 14px;
    }
    &__img {
        position: absolute;
        z-index: -1;
        width: 100%;
        max-height: 348px;
        height: 100%;

        img{
            max-height: 348px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @media(max-width:$md3){
            max-height: 263px;
            img{
                max-height: 263px;
            }
        }
        @media(max-width:360px){
            max-height: 293px;
            img{
                max-height: 293px;
            }
        }
    }

    &__container{
        padding: 15px;
        position: relative;
        z-index: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__block-title-short{
        max-width: 592px;
        width: 100%;
        text-align: center;
        padding: 48px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
    }

    &__block-with-button{
        display: flex;
        align-items: center;
        gap: 24px;
        max-width: 1000px;
        width: 100%;
        padding: 59px 100px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 10px;
        justify-content: space-between;

        .main__content-button{
            align-self: flex-end;
        }

        @media (max-width:$md3){
            flex-direction: column;
            padding: 24px;
            justify-content: center;
            .main__content-button{
                align-self: center;
            }
        }
    }

    &__content-button{
        display: flex;
        padding: 16px 32px;
        gap: 10px;
        align-items: center;
        max-width: max-content;
    }



    &__block-title{

    }
    // .main__content-title

    &__content-title {
        color: $title;
    }

    &__block-text{
        @media(max-width:$md3){
            text-align: center;
        }
    }

}


.zalushutu-vidhyk{
    .main {
        position: relative;
        height: 348px;
        &__img {
            max-height: 348px !important;
            height: 100% !important;
            img{
                max-height: 348px !important;
                height: 100% !important;
            }
        }
    }
}
