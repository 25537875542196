.errors {
    padding: 150px 0;
    @media(max-width:$md2){
        padding: 80px 0;
    }
    // .errors__container

    &__container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
    }

    // .errors__title

    &__title {
        font-weight: 600;
        font-size: 64px;
        line-height: 87px;
        text-align: center;
        color: $red;
        @media(max-width:$md3){
            font-size: 60px;
            line-height: 82px;
        }
    }

    // .errors__text

    &__text {
        color: $title;
        max-width: 592px;
        text-align: center;
        @media(max-width:$md3){
            font-size: 16px !important;
        }
    }

    // .errors__link

    &__link {
        color: $red;
        margin-top: 34px;
        max-width: max-content;
        padding: 16px 32px;
        @media(max-width:$md3){
            margin-top: 60px;
        }
    }

    .none{
        @media(max-width: $md3){
            display: none;
        }
    }
}

.table-info-none {
    display: flex;
    flex-direction: column;
    gap: 58px;
    align-items: center;
}
.table-info-block {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: center;
    svg{
        min-width: 25px ;
        min-height: 25px;
        max-width: 25px;
        max-height: 25px;
        width: 100%;
        height: 100%;
    }
}
.table-info-text {
    color: $text3;
}
.table-info-link {
    margin: 0 auto;
    margin-top: 56px !important;
}


.partners {

    .partners__container {
    // margin-top: 40px;
    // #replaceCode {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 32px 20px;
        @media(max-width:$md2){
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media(max-width:$md3){
            grid-template-columns: 1fr 1fr;
        }
        @media(max-width:575px){
            grid-template-columns: 1fr;
            justify-items: center;
        }
    }

    // .partners__cart

    &__cart {
        width: 100%;
        max-width: 360px;
        padding: 24px;
        background: $background;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
    }

    // .partners__cart-logo

    &__cart-logo {
        max-width: 232px;
        height: 140px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 44px;
        border-radius: 10px;
        img{
            max-height: 140px;
            height: 100%;
            max-width: 232px;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
        }

    }
}
.swiper-wrapper{
    .partners__cart-logo{
        @media(max-width:$md4){
            max-width: 180px;
            max-height: 90px;
        }
    }
}

.mass-media {

    // .mass-media__container

    &__container {

        // #replaceCode{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 56px 32px;
            @media(max-width:$md2){
                grid-template-columns: 1fr 1fr;
                justify-items: center;
                row-gap: 46px;
            }
            @media(max-width:$md3){
                grid-template-columns: 1fr;
            }
        // }
    }

    // .mass-media__cart

    &__cart {
        display: flex;
        flex-direction: column;
        gap: 24px;
        max-width: 400px;
        width: 100%;
        @media(max-width:$md3){
            gap: 16px;
        }
    }


    &__block-video{
        border-radius: 10px;
        max-width: 400px;
        max-height: 384px;
        min-height: 384px;
        @media(max-width:$md3){
            min-height: 315px;
            max-height: 315px;
        }
        // min-width: 384px;
    }
    // .mass-media__video

    &__video {
        max-width: 400px;
        max-height: 384px;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
        @media(max-width:$md3){
            min-height: 315px;
            max-height: 315px;
        }
    }

    // .mass-media__title

    &__title {
        font-size: 18px;
        line-height: 150%;
        color: $title;
        @media(max-width:$md4) {
            font-size: 16px;
        }
    }
}


/////////////////////////////////////////////////////////
/// pagination/////////////////
.paginatinon {
    margin-top: 56px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    @media(max-width:$md2){
        margin-top:40px;
        gap:40px
    }

}
.pagination-button {
    max-width: max-content;
    border: 1px solid $border;
    border-radius: 10px;
    padding: 16px 32px;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: $title;
    transition: 0.3s;
    &:hover{
        background-color: $hover-red;
        border-color: $hover-red;
        color: $white;
    }
}
.href{
  cursor: pointer;
}
.pagination-list {
    display: flex;
    gap: 32px;
    align-items: center;
}
.pagination-item {
}
.pagination-link {
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    color: $subtitle;
    transition: 0.3s;
    &:hover{
        color: $hover-red;

    }
}

.pagination-active{

    background-color: #F2F4F8;
    padding: 17px 25px;
    border-radius: 100%;
    a{
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
        color: $title;
    }
}

.actions__container{
    display: flex;
    flex-direction: column;
    gap: 56px;
    align-items: center;
}

.actions__content-desktop{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    gap: 36px 32px;
    @media(max-width:$md2){
        // display: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        &.active-actions{
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
    }
}


.blog-swiper{
    position: relative;
    z-index: 0;
    .blog__cart{
        background-color: $background !important;
    }
    .blog-swiper__container{
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
    }

    .blog-swiper__title{
        color: $title;
    }

    .blog-swiper__content{
        width: 100%;
        display: flex;
        gap: 32px;
        align-items: center;
    }
    .swiper{
        width: 100%;
        padding: 40px 0;
    }

    .swiper-button-prev{
        position: relative !important;
        min-width: 64px;
        max-width: 64px;
        min-height: 64px;
        max-height: 64px;
        width: 64px !important;
        height: 64px !important;
        border-radius: 100%;
        background-color: $background;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            rotate: 180deg;
        }
        &::after{
            content: none;
        }
        @media(max-width:$md2){
            display: none;
        }
    }

    .swiper-button-next{
        position: relative !important;
        min-width: 64px;
        max-width: 64px;
        min-height: 64px;
        max-height: 64px;
        width: 64px !important;
        height: 64px !important;
        border-radius: 100%;
        background-color: $background;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after{
            content: none;
        }
        @media(max-width:$md2){
            display: none;
        }
    }
    .swiper-pagination{
        position: relative;
        margin-top: 20px;
    }
}



.sertificate__cart{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
    }
}
.sertificate__container{
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
}

.sertificate__title{
    color: $title;
}

.sertificate__content{
    z-index: 0;
    width: 100%;
    display: flex;
    gap: 32px;
    align-items: center;
    .swiper-button-prev{
        position: relative !important;
        min-width: 64px;
        max-width: 64px;
        min-height: 64px;
        max-height: 64px;
        width: 64px !important;
        height: 64px !important;
        border-radius: 100%;
        background-color: $background;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            rotate: 180deg;
        }
        &::after{
            content: none;
        }
        @media(max-width:$md2){
            display: none;
        }
    }

    .swiper-button-next{
        position: relative !important;
        min-width: 64px;
        max-width: 64px;
        min-height: 64px;
        max-height: 64px;
        width: 64px !important;
        height: 64px !important;
        border-radius: 100%;
        background-color: $background;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after{
            content: none;
        }
        @media(max-width:$md2){
            display: none;
        }
    }
    .swiper{
        width: 100%;
        height: 460px;
        padding: 40px 0 !important;
    }
    .swiper-pagination{
        position: relative;
        margin-top: 20px;
    }

    .swiper-slide{
        height: 100%;
    }

}





.achievement {

    // .achievement__container

    &__container {
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
        .sertificate__content{
            display: none;
            @media(max-width:$md3){
                display: flex;
            }
        }
    }

    &__blocks{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 32px;
        @media(max-width:$md2){
            grid-template-columns: 1fr 1fr;
            justify-items: center;
        }
        @media(max-width:$md3){
            display: none;
        }
    }

    &__sertificate {
        max-width: 384px;
        width: 100%;
        height: 384px;
        img{
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
        }
    }


}











.select {
    position: relative;
    // margin-bottom: 15px;
    width: 250px;
    // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  .select .selectBtn {
    background: var(--bg1);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background: #fff;
  }
  .select .selectBtn:after {
    content: "";
    position: absolute;
    top: 45%;
    right: 27px;
    width: 8px;
    height: 8px;
    -webkit-transform: translateY(-50%) rotate(45deg);
            transform: translateY(-50%) rotate(45deg);
    border-right: 2px solid #BDC2D2;
    border-bottom: 2px solid #BDC2D2;
    transition: 0.2s ease;
  }
  .select .selectBtn.toggle {
    border-radius: 3px 3px 0 0;
  }
  .select .selectBtn.toggle:after {
    -webkit-transform: translateY(-50%) rotate(-135deg);
            transform: translateY(-50%) rotate(-135deg);
  }
  .select .selectDropdown {
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    
    width: 100%;
    margin-top: -10px !important;
    border-radius: 0 0 3px 3px;
    background: var(--bg1);
    border-top: 1px solid #eee;
    z-index: 99999;
    background: #fff;
    -webkit-transform: scale(1, 0);
            transform: scale(1, 0);
    -webkit-transform-origin: top center;
            transform-origin: top center;
    visibility: hidden;
    transition: 0.2s ease;
    box-shadow: 0 4px 5px rgba(123, 123, 123, 0.3);
    @media(max-width:$md4){
        max-height: 120px;
    }
  }

  .selectDropdown::-webkit-scrollbar {
    background: $background;
    width: 8px;
}

.selectDropdown::-webkit-scrollbar-thumb {
    background: $red;
    border-radius: 5px;
}
  .select .selectDropdown .option {
    padding: 4px 24px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .option{
    @media(max-width:$md4){
        padding: 4px 10px !important;
        font-size: 14px !important;
        line-height: 20px !important;
    }
  }
  .select .selectDropdown .option:hover {
    background: #f8f8f8;
  }
  .select .selectDropdown.toggle {
    visibility: visible;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }


  .rating {
    margin: auto;
  }
  .rating__display {
    font-size: 1em;
    font-weight: 500;
    min-height: 1.25em;
    position: absolute;
    top: 100%;
    width: 100%;
    text-align: center;
    &.rating__star-fil:hover ~ .rating__star-fill{

        fill: #EAB327;
        transform: scale(1);

      }
  }
  .rating__stars {
    display: flex;
    padding-bottom: 0.375em;
    position: relative;
    max-width: max-content;
        &:hover .rating__label {
            .rating__star-fill{
                fill: #EAB327;
                transform: scale(1);
            }
        }
        &:hover .rating__label:hover ~.rating__label{
            .rating__star-fill{
            fill: #EAB327;
            transform: scale(0);
        }
      }
  }
  .rating__star {
    display: block;
    overflow: visible;
    pointer-events: none;
    width: 2em;
    height: 2em;

  }
  .rating__star-ring, .rating__star-fill, .rating__star-line, .rating__star-stroke {
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  .rating__star-ring, .rating__star-fill, .rating__star-line {
    stroke: #EAB327;
  }
  .rating__star-fill {
    fill: #EAB327;
    transform: scale(0);
    transition: fill var(--trans-dur) var(--bezier), transform var(--trans-dur) var(--bezier);
  }
  .rating__star-stroke {
    stroke: #EAB327;
    transition: stroke var(--trans-dur);

  }
  .rating__label {
    cursor: pointer;

  }
  .rating__label--delay1 .rating__star-ring, .rating__label--delay1 .rating__star-fill, .rating__label--delay1 .rating__star-line, .rating__label--delay1 .rating__star-stroke {
    animation-delay: 0.05s;
  }
  .rating__label--delay2 .rating__star-ring, .rating__label--delay2 .rating__star-fill, .rating__label--delay2 .rating__star-line, .rating__label--delay2 .rating__star-stroke {
    animation-delay: 0.1s;
  }
  .rating__label--delay3 .rating__star-ring, .rating__label--delay3 .rating__star-fill, .rating__label--delay3 .rating__star-line, .rating__label--delay3 .rating__star-stroke {
    animation-delay: 0.15s;
  }
  .rating__label--delay4 .rating__star-ring, .rating__label--delay4 .rating__star-fill, .rating__label--delay4 .rating__star-line, .rating__label--delay4 .rating__star-stroke {
    animation-delay: 0.2s;
  }
  .rating__input {
    -webkit-appearance: none;
    appearance: none;
  }
  .rating__input:hover ~ [data-rating]:not([hidden]) {
    display: none;
  }
  .rating__input-1:hover ~ [data-rating="1"][hidden], .rating__input-2:hover ~ [data-rating="2"][hidden], .rating__input-3:hover ~ [data-rating="3"][hidden], .rating__input-4:hover ~ [data-rating="4"][hidden], .rating__input-5:hover ~ [data-rating="5"][hidden], .rating__input:checked:hover ~ [data-rating]:not([hidden]) {
    display: block;
  }
  .rating__input-1:hover ~ .rating__label:first-of-type .rating__star-stroke, .rating__input-2:hover ~ .rating__label:nth-of-type(-n + 2) .rating__star-stroke, .rating__input-3:hover ~ .rating__label:nth-of-type(-n + 3) .rating__star-stroke, .rating__input-4:hover ~ .rating__label:nth-of-type(-n + 4) .rating__star-stroke, .rating__input-5:hover ~ .rating__label:nth-of-type(-n + 5) .rating__star-stroke {
    stroke: #EAB327;
    transform: scale(1);
  }
  .rating__input-1:checked ~ .rating__label:first-of-type .rating__star-ring, .rating__input-2:checked ~ .rating__label:nth-of-type(-n + 2) .rating__star-ring, .rating__input-3:checked ~ .rating__label:nth-of-type(-n + 3) .rating__star-ring, .rating__input-4:checked ~ .rating__label:nth-of-type(-n + 4) .rating__star-ring, .rating__input-5:checked ~ .rating__label:nth-of-type(-n + 5) .rating__star-ring {
    animation-name: starRing;
  }
  .rating__input-1:checked ~ .rating__label:first-of-type .rating__star-stroke, .rating__input-2:checked ~ .rating__label:nth-of-type(-n + 2) .rating__star-stroke, .rating__input-3:checked ~ .rating__label:nth-of-type(-n + 3) .rating__star-stroke, .rating__input-4:checked ~ .rating__label:nth-of-type(-n + 4) .rating__star-stroke, .rating__input-5:checked ~ .rating__label:nth-of-type(-n + 5) .rating__star-stroke {
    animation-name: starStroke;
  }
  .rating__input-1:checked ~ .rating__label:first-of-type .rating__star-line, .rating__input-2:checked ~ .rating__label:nth-of-type(-n + 2) .rating__star-line, .rating__input-3:checked ~ .rating__label:nth-of-type(-n + 3) .rating__star-line, .rating__input-4:checked ~ .rating__label:nth-of-type(-n + 4) .rating__star-line, .rating__input-5:checked ~ .rating__label:nth-of-type(-n + 5) .rating__star-line {
    animation-name: starLine;
  }
  .rating__input-1:checked ~ .rating__label:first-of-type .rating__star-fill, .rating__input-2:checked ~ .rating__label:nth-of-type(-n + 2) .rating__star-fill, .rating__input-3:checked ~ .rating__label:nth-of-type(-n + 3) .rating__star-fill, .rating__input-4:checked ~ .rating__label:nth-of-type(-n + 4) .rating__star-fill, .rating__input-5:checked ~ .rating__label:nth-of-type(-n + 5) .rating__star-fill {
    animation-name: starFill;
  }
  .rating__input-1:not(:checked):hover ~ .rating__label:first-of-type .rating__star-fill, .rating__input-2:not(:checked):hover ~ .rating__label:nth-of-type(2) .rating__star-fill, .rating__input-3:not(:checked):hover ~ .rating__label:nth-of-type(3) .rating__star-fill, .rating__input-4:not(:checked):hover ~ .rating__label:nth-of-type(4) .rating__star-fill, .rating__input-5:not(:checked):hover ~ .rating__label:nth-of-type(5) .rating__star-fill {
    fill: #EAB327;
  }
  .rating__sr {
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --bg: #EAB327;
      --fg: #EAB327;
    }

    .rating {
      margin: auto;
    }
    .rating__star-stroke {
      stroke: #EAB327;
    }
  }
  @keyframes starRing {
    from, 20% {
      animation-timing-function: ease-in;
      opacity: 1;
      r: 8px;
      stroke-width: 16px;
      transform: scale(0);
    }
    35% {
      animation-timing-function: ease-out;
      opacity: 0.5;
      r: 8px;
      stroke-width: 16px;
      transform: scale(1);
    }
    50%, to {
      opacity: 0;
      r: 16px;
      stroke-width: 0;
      transform: scale(1);
    }
  }
  @keyframes starFill {
    from, 40% {
      animation-timing-function: ease-out;
      transform: scale(0);
    }
    60% {
      animation-timing-function: ease-in-out;
      transform: scale(1.2);
    }
    80% {
      transform: scale(0.9);
    }
    to {
      transform: scale(1);
    }
  }
  @keyframes starStroke {
    from {
      transform: scale(1);
    }
    20%, to {
      transform: scale(0);
    }
  }
  @keyframes starLine {
    from, 40% {
      animation-timing-function: ease-out;
      stroke-dasharray: 1 23;
      stroke-dashoffset: 1;
    }
    60%, to {
      stroke-dasharray: 12 12;
      stroke-dashoffset: -12;
    }
  }


