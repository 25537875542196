.doctor {

    // .doctor__container

    &__container {
        display: flex;
        gap: 30px;
        justify-content: space-between;
        @media(max-width:$md3){
            flex-direction: column-reverse;
        }
    }

    // .doctor__left

    &__left {
        display: flex;
        flex-direction: column;
        gap: 32px;
        max-width: 700px;
        width: 100%;
    }

    // .doctor__header

    &__header {
        display: flex;
        gap: 30px;
        justify-content: space-between;
        @media(max-width:$md3){
            display: none;
            flex-direction: column;
            max-width: 500px;
            width: 100%;
        }

    }

    // .doctor__name

    &__name {
        color: $title;
        font-size: 32px !important;
        @media(max-width:$md3){
            font-size: 18px !important;
        }
    }

    &__location {
        color: $title;
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        margin-top: 6px;
        span{
            color: $red;
        }
    }
    // .doctor__socials-list

    &__socials-list {
        display: flex;
        gap: 16px;
        align-items: center;
    }

    // .doctor__social-item

    &__social-item {
    }

    // .doctor__link

    &__link {
    }

    // .doctor__text

    &__text {
        color: $text3;
    }

    // .doctor__content

    &__content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        ul{

            li{
                list-style: outside;
                margin-left: 20px;
                color: $text3;
            }
        }
    }

    // .doctor__block

    &__block {
        border-radius: 10px;
        border: 1px solid $border;
        padding: 16px 24px;
        display: flex;
        gap: 16px;
    }

    // .doctor__block-icon

    &__block-icon {

    }

    // .doctor__block-info

    &__block-info {
        display: flex;
        flex-direction: column;
        gap: 12px;
        font-size: 16px;
        line-height: 22px;
        color: #515668;
    }

    // .doctor__block-title

    &__block-title {
        color: $title;
        font-size: 18px;
    }

    // .doctor__block-list

    &__block-list {
        display: flex;
        flex-direction: column;
    }

    // .doctor__block-item

    &__block-item {
    }

    // .doctor__right

    &__right {
        display: flex;
        flex-direction: column;
        gap: 32px;
        max-width: 382px;
        width: 100%;
        @media(max-width:$md3){
            max-width: 100%;
            align-items: center;
        }
    }

    // .doctor__img

    &__img {
        width: 100%;
        height: 562px;
        border-radius: 10px;
        img{
            border-radius: 10px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @media(max-width:$md3){
            max-width: 500px;
            width: 100%;
            height: 420px;
        }
        @media(max-width:$md4){
            max-width: 500px;
            width: 100%;
            height: 360px;
        }
    }

    // .doctor__modal

    &__modal {
        max-width: 100%;
        @media(max-width:$md3){
            max-width: 500px;
            width: 100%;
        }
    }

    .for-mobile {
        display: none;
        @media(max-width:$md3){
            display: flex !important;
        }
    }
}



