.service {

    // .service__container

    &__container {
        position: relative;
        display: flex;
        gap: 32px;
        @media(max-width:1024px){
            flex-direction: column;
            align-items: center;
        }
        // p{

        // }
    }

    // .service__left

    &__left {
        position: sticky;
        height: calc(100vh - 200px);
        top: 160px;
        overflow: auto;
        width: 280px;
        min-width: 280px;
        display: flex;
        flex-direction: column;
        @media(max-width:1024px){
            height: auto;
            max-width: 600px;
            width: 100%;
            align-items: center;
            position: relative;
            border: 1px solid $border;
            border-radius: 10px;
            padding: 13.5px;
            top: 0;
        }
    }

    .service__left::-webkit-scrollbar {
        background: #D9D9D9;
        border-radius: 5px;
        width: 5px;
    }

    .service__left::-webkit-scrollbar-thumb {
        background: $red;
        border-radius: 5px;
    }


    &__left-content{
        width: 100%;
        &.active{

            .service__left-list{
                display: flex !important;
            }
            .rotate-arrow__block{
                transform: rotate(0deg);
            }
        }
    }

    // .service__left-header

    &__left-header {
        display: none;
        width:100%;
        align-items: center;
        justify-content: space-between;
        @media(max-width:1024px){
            display: flex;
        }
        .rotate-arrow__block{
            transition: 0.3s;
            transform: rotate(180deg);
        }
    }

    &__left-subtitle{
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        color: $title;
        margin-bottom: 12px;
        @media(max-width:1024px){
            display: none;
        }
    }

    // .service__left-header-item

    &__left-header-item {
        display: flex;
        align-items: center;
        gap: 13.5px;
    }

    // .service__left-title

    &__left-title {
        color: $red;
    }

    // .service__left-list

    &__left-list {
        display: flex;
        flex-direction: column;
        gap: 12px !important;
        width: 100%;
        padding-right: 12px;
        @media(max-width:1024px){
            margin-top: 24px;
            padding-right: 0;
            display: none !important;
        }
    }

    // .service__list-item

    &__list-item {
        font-size: 14px !important;
        // font-weight: 300 !important;
    }

    // .service__list-anchor

    &__list-anchor {
        font-size: 15px;
        line-height: 150%;
        color: $subtitle;
        &.active{
            color: $red;
        }
    }

    // .service__right

    &__right {
        display: flex;
        flex-direction: column;
        background-color: $white !important;
        width: calc(100% - 280px - 50px);
        @media(max-width:1024px){
            width: 100%;
        }
        // div{
        //     background-color: $white !important;
        // }
        a{
            // background-color: $white !important;
            color: $red;
        }
        p{
            margin-top: 24px ;
            font-weight: 400 ;
            font-size: 14px ;
            line-height: 19px;
            color: $text3;
        }
        .prices__block{
            margin-top: 32px;
            p{
                margin-top:  0px ;
            }
        }
        .service__response-cart-author{
            margin-top:  0px ;
        }
        .service__response-service{
            margin-top:  0px ;
        }
        .doctors__cart-about{
            margin-top:  0px ;
        }
        .doctors__cart-img{
            height: 300px !important;
        }
        // .service__flex-block{
        //     background-color: $background !important;
        //     div{
        //         background-color: $background !important;
        //     }
        //     p{
        //         background-color: $background !important;
        //     }
        // }
        .content-bloc{
            margin-top: 0 !important;
        }
    }

    // .service__content

    &__content {
        background-color: $white !important;
        display: flex;
        flex-direction: column;
        ul{
            background-color: $white !important;
            li{
                list-style: outside !important;
                margin-left: 20px !important;
            }
        }
        .decline{
            display: flex;
            flex-direction: column;
            gap: 26px;

            li{
                margin-left: 40px !important;
                list-style: none !important;
                ol{
                    gap: 10px;
                    li{
                        list-style: auto !important;
                        &::before{
                            display: none;
                        }
                    }
                }
                ul{
                    gap: 10px;
                    li{
                        list-style: outside !important;
                        &::before{
                            display: none;
                        }
                    }
                }
            }
            @media(max-width:$md3){
                gap: 16px !important;
            }
        }

        .checkbox{
            display: flex;
            flex-direction: column;
            gap: 26px !important;
            li{
                margin-left: 40px !important;
                list-style: none !important;
                ol{
                    margin-top: 6px;
                    gap: 10px;
                    li{
                        list-style: auto !important;
                        &::before{
                            display: none;
                        }
                    }
                }
                ul{
                    margin-top: 6px;
                    gap: 10px;
                    li{
                        list-style: outside !important;
                        &::before{
                            display: none;
                        }
                    }
                }
            }
            @media(max-width:$md3){
                gap: 16px !important;
            }
        }
        ol{
            background-color: $white !important;
            display: flex;
            flex-direction: column;
            gap: 24px;
            li{
                background-color: $white !important;
                font-weight: 500 !important;
                font-size: 16px !important;
                line-height: 22px !important;
                color: $title !important;
                ul , .checkbox , .decline{
                    gap: 10px;
                    margin-top: 6px;
                    li{
                        // font-weight: 700 !important;
                        list-style: outside !important;
                        display: list-item !important;
                        &::before{
                            display: none !important;
                            content: none !important;
                        }
                    }
                    margin-bottom: 14px;
                }
            }
            @media(max-width:$md3){
                li{
                    // font-size: 14px !important;
                    line-height: 19px !important;
                }
            }
        }
        .service__content-title:first-child{
            // margin-top: 0 !important;
        }
    }

    // .service__content-block

    &__content-block {
        .container {
            padding: 0;
        }
    }



    // .service__content-title

    &__content-title {
        background-color: $white !important;
        margin-top: 96px !important;
        @media(max-width:$md3){
            // text-align: center !important;
            line-height: 25px !important;
        }
    }

    // .service__content-container

    &__content-container {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    // .service__block-content

    &__block-content-text {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__block-content {
        display: flex;
        flex-direction: column;
        gap: 56px;
    }

    // .service__block-text

    &__block-text {
        margin-top: 24px !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 19px !important;
        color: $text3 !important;
    }

    // .service__block-list

    &__block-list {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    // .service__block-subtitle

    &__block-subtitle {
        margin-top: 32px !important;
        font-weight: 600 !important;
        font-size: 24px !important;
        line-height: 33px !important;
        color: #272B38 !important;
        @media(max-width:$md3){
            font-size: 16px !important;
            line-height: 22px !important;
            text-align: center !important;
        }
    }

    // .service__list

    &__list {
    }

    // .service__block-imgs

    &__block-imgs {
        margin-top:56px ;
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        align-items: center;
        @media(max-width:$md3){
            margin-top: 40px;
        }
        @media(max-width:$md4){

            flex-direction: column;
            gap: 32px;
        }
    }

    // .service__block-img

    &__block-img {
        max-width: 436px;
        width: 100%;
        height: 436px;
        border-radius: 10px;
        flex: 1 1 320px;
        img{
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
        }
        @media(max-width:1024px){
            height: 336px;
        }
        @media(max-width:$md4){
            flex: auto;
            height: 228px;
        }
    }

    &__list{
        margin-top: 25px;
    }

    &__buttons{
        margin: 0 auto;
        margin-top: 56px;
        display: flex;
        gap:15px;
        flex-wrap: wrap;
        justify-content: center;
        .service__btn{
            margin: 0 !important;
        }
        // width: max-content;
    }
    // .service__btn

    &__btn {
        color: #FFFFFF !important;
        margin: 0 auto;
        // margin-top: 56px;
    }

    // .service__block-list-with-img

    &__block-list-with-img {
        :first-child{
            margin-top: 0 !important;
        }

        margin-top: 32px;
        display: flex;
        gap: 32px;
        ul{
            width: 100%;
        }
        @media(max-width:$md3){
            flex-direction: column-reverse;
        }
    }

    // .service__block-img-with-list

    &__block-img-with-list {
        display: flex;
        height: 388px;
        max-width: 482px;
        min-width: 340px;
        width: 100%;
        border-radius: 10px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
        @media(max-width:$md3){
            height: 336px;
            max-width: 100%;
            min-width: 100;
        }
        @media(max-width:$md4){
            height: 228px;
        }
    }
    &__video{
        margin-top: 56px;
        width: 100%;
        height: 438px;
        iframe{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
        @media(max-width:$md3){
            height: 336px;
            margin-top: 40px;
        }
    }
    ul{
        background-color: $white !important;
        display: flex;
        flex-direction: column;
        gap: 24px;
        li{
            background-color: $white !important;
            font-weight: 500 !important;
            font-size: 16px !important;
            line-height: 22px !important;
            color: $title !important;
            ol{
                margin-top: 6px;
                gap: 10px;
                li{
                    list-style: auto !important;
                }
            }
            ul{
                gap: 10px;
                li{
                    list-style: outside !important;
                    &::before{
                        display: none;
                    }
                }
            }
        }
        @media(max-width:$md3){
            li{
                // font-size: 14px !important;
                line-height: 19px !important;
            }
        }
    }
    ol{
        background-color: $white !important;
        display: flex;
        flex-direction: column;
        gap: 24px;
        li{
            background-color: $white !important;
            font-weight: 500 !important;
            font-size: 16px !important;
            line-height: 22px !important;
            color: $title !important;
            ul{
                gap: 10px;
                margin-top: 6px;
                li{
                    // font-weight: 700 !important;
                    list-style: outside !important;
                    display: list-item !important;
                    &::before{
                        display: none;
                        content: none;
                    }
                }
                margin-bottom: 14px;
            }
        }
        @media(max-width:$md3){
            li{
                // font-size: 14px !important;
                line-height: 19px !important;
            }
        }
    }


    .swiper__content{
        position: relative;
        z-index: 1;
        margin-top: 44px;
        width: 100%;
        display: flex;
        gap: 24px;

        @media(max-width:1024px){
            align-items: center;
        }

        // align-items: center;
        .swiper{
            // width: 100%;
            padding: 40px 18px;
            .doctors__cart{
                height: 100%;
            }
            .doctors__cart-block{
                height: 100%;
                justify-content: space-between;
                // max-width: max-content;
                // &:hover{
                //     transform: scale(1.02) !important;
                // }
            }
        }

        .swiper-slide{
            display: flex;
            justify-content: center;
            height: auto !important;
            min-width: 300px !important;
            @media(max-width:$md3){
                min-width: 270px !important;
            }
        }

        .swiper-button-prev{
            position: relative !important;
            min-width: 64px;
            max-width: 64px;
            min-height: 64px;
            max-height: 64px;
            width: 64px !important;
            height: 64px !important;
            border-radius: 100%;
            background-color: $background;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                rotate: 180deg;
            }
            &::after{
                content: none;
            }
            @media(max-width:$md2){
                display: none;
            }
            // &.swiper-button-disabled {
            //     display: none;
            // }
        }

        .swiper-button-next{
            position: relative !important;
            min-width: 64px;
            max-width: 64px;
            min-height: 64px;
            max-height: 64px;
            width: 64px !important;
            height: 64px !important;
            border-radius: 100%;
            background-color: $background;
            display: flex;
            align-items: center;
            justify-content: center;
            &::after{
                content: none;
            }
            @media(max-width:$md2){
                display: none;
            }
            // &.swiper-button-disabled {
            //     display: none;
            // }
        }

        .swiper-pagination{
            position: relative;
            margin-top: 20px;
        }
        .swiper-wrapper.disabled {
            transform: translate3d(0px, 0, 0) !important;
        }
        // .swiper-pagination.disabled {
        //     display: none;
        // }
    }


    // .service__content-flex

    &__content-flex {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        @media(max-width:$md3){
            margin-top: 32px;

            gap: 24px;
        }
    }

    // .service__flex-block

    &__flex-block {
        // // flex: 1 0 241px;
        display: flex;
        // // max-width: 436px;
        // // width: 100%;
        gap: 20px;
        justify-content: space-between;
        align-items: center;
        padding: 43px;
        background-color: $background !important;
        border-radius: 10px;
        @media(max-width:$md2){
            padding: 16px;
            gap: 8px;
        }
    }

    // .service__flex-gif

    &__flex-gif {
        max-width: 96px;
        width: 100%;
        min-width: 96px;
        height: 96px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @media(max-width:$md2){
            max-width: 63px;
            width: 100%;
            min-width: 63px;
            height: 63px;
        }
    }

    // .service__flex-text

    &__flex-text {
        margin-top:  0px ;
        font-family: 'Inter';
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $text3;
        width: 100%;
    }



    // .service__answer-block

    &__answer-block {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        transition: all 0.5s;
        &.active{
            transition: all 0.5s;
            .service__answer-block-header{
                background-color: $background;
                box-shadow: none;
            }
            .service__answer-block-info{
                position: static;
                top: 0;
                opacity: 1;
                visibility: visible;
                z-index: 1;
                // display: block;
            }
            .arrow__block{
                transition: all 0.5s;
                transform: rotate(180deg);
                background-color: $red;
                svg{
                    path{
                        fill: $white;
                        stroke:$white;
                    }
                }
            }
        }
        // &:nth-last-child(1){
        //     margin-bottom: 100px;
        // }
    }

    // .service__answer-block-header

    &__answer-block-header {
        background-color: $white;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
        padding: 16px 32px;
        display: flex;
        justify-content: space-between;
        gap: 30px;
        align-items: center;
        transition: all 0.5s;
        &:hover{
            transition: all 0.5s;
            transform: scale(1.01);
            box-shadow: 0px 30px 30px rgba(44, 68, 142, 0.06);
        }
        @media(max-width:$md3){
            gap: 15px;
        }
    }

    // .service__answer-block-title

    &__answer-block-title {
    }

    // .service__answer-block-info

    &__answer-block-info {
        position: absolute;
        z-index: -1;
        top: -100%;
        opacity: 0;
        visibility: hidden;
        font-size: 12px;
        margin-top:  0px ;
        line-height: 200%;
        padding: 16px 32px;
        // color: #FFFFFF;
        transition: all 0.5s;
        color: $subtitle;
    }

    .arrow__block{
        cursor: pointer;
        transition: all 0.5s;
        min-width: 60px;
        min-height: 60px;
        max-height: 60px;
        max-width: 60px;
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: $background;
        &:hover{
            transition: all 0.5s;
            transform: scale(0.85);
        }
        @media(max-width:$md4){
            min-width: 52px;
            min-height: 52px;
            max-height: 52px;
            max-width: 52px;
            height: 52px;
            width: 52px;
        }
    }


    &__quote{
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    &__quote-text{
        font-family: 'Inter' !important;
        font-style: italic;
        margin-top:  0px  !important;
        font-size: 18px !important;
        line-height: 22px !important;
        color: $text3;
        border-left: 4px solid $red;
        padding-left: 24px;
        @media(max-width:$md3){
            font-size: 14px !important;
            line-height: 17px !important;
        }
    }
    &__quote-author{
        text-align: right;
        font-weight: 500 !important;
        font-size: 18px !important;
        margin-top:  0px  !important;
        line-height: 150% !important;
        color: $title !important;
        font-style: italic;
        @media(max-width:$md3){
            font-size: 14px;
        }
    }

    // .service__table

    // &__table {
    //     margin-top: 56px;
    //     border-collapse: collapse;
    //     border-top: 1px solid $border;
    //     border-bottom: 1px solid $border;
    //     text-align: start;
    // }
    table{
        margin-top: 56px;
        border-collapse: collapse;
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;
        text-align: start;
        tr{
            border-bottom: 1px solid $border;
            &:first-child {
                td {
                    padding: 24px 15px;
                    text-align: left;
                    font-weight: 500 !important;
                    font-size: 18px !important;
                    line-height: 150% !important;
                    color: $title !important;
                    &:first-child {
                        border-right: 1px solid $border;
                        padding: 24px 15px 24px 0;
                    }
                    &:last-child {
                        padding-left: 15px;
                    }
                    @media(max-width:$md3){
                        padding: 16px 12px;
                        &:last-child {
                            padding-left: 12px;
                        }
                    }
                }
            }
        }
        th{
            padding: 24px 15px;
            text-align: left;
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
            color: $title;
            &:first-child {
                border-right: 1px solid $border;
                padding: 24px 15px  24px 0;
            }
            &:last-child {
                padding-left: 15px;
            }
            @media(max-width:$md3){
                padding: 16px 12px;
                &:last-child {
                    padding-left: 12px;
                }
            }
        }
        td{
            width: auto;
            border-right: 1px solid $border;
            padding: 24px 15px;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: $subtitle;
            text-align: start;
            vertical-align: top;
            @media(max-width:$md3){
                padding: 16px 12px 16px 0;
            }

            &:first-child {
                padding: 24px 15px  24px 0;
            }


            &:last-child {
                border-right-color: transparent;
                padding: 24px 15px;
            }
        }
    }


    // .service__table-row

    // &__table-row {
    //     border-bottom: 1px solid $border;
    // }

    // .service__table-title

    // &__table-title {
    //     padding: 24px 15px 24px 0;
    //     text-align: left;
    //     font-weight: 500;
    //     font-size: 18px;
    //     line-height: 150%;
    //     color: $title;
    //     &:nth-child(1){
    //         border-right: 1px solid $border;
    //     }
    //     &:nth-child(2){
    //         padding-left: 15px;
    //     }
    //     @media(max-width:$md3){
    //         padding: 16px 12px;
    //         &:nth-child(2){
    //             padding-left: 12px;
    //         }
    //     }
    // }

    // .service__table-question

    // &__table-question {
    //     width: 50%;
    //     border-right: 1px solid $border;
    //     padding: 24px 15px 24px 0;
    //     font-weight: 400;
    //     font-size: 16px;
    //     line-height: 150%;
    //     color: $subtitle;
    //     text-align: start;
    //     vertical-align: top;
    //     @media(max-width:$md3){
    //         padding: 16px 12px 16px 0;
    //     }
    // }

    // // .service__table-answer

    // &__table-answer {
    //     padding: 24px 15px ;
    //     width: 50%;
    //     font-weight: 400;
    //     font-size: 16px;
    //     line-height: 150%;
    //     color: $subtitle;
    //     text-align: start;
    //     @media(max-width:$md3){
    //         padding: 16px 12px;
    //     }
    // }

    // .service__comment

    &__comment {
        margin-top: 56px;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    // .service__comment-top

    &__comment-top {
        display: flex;
        align-items: center;
        gap: 30px;
        padding: 16px 32px;
        border-radius: 10px;
        background-color: $background;
        svg{
            min-height: 28px;
            min-width: 23px;
        }
        @media(max-width:$md3){
            align-items: flex-start;
        }
    }



    &__comment-text {
        font-style: italic;
        margin-top:  0px !important ;
        font-size: 18px !important;
        line-height: 150% !important;
        color: #272B38 !important;
        @media(max-width:$md3){
            font-size: 16px !important;
        }
    }
}

.service {

    // .service__response-tab

    &__response-tab {
        margin-top: 100px;

    }

    // .service__response-tab-block

    &__response-tab-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    // .service__tab-block-content

    &__tab-block-content {
        display: flex;
        gap: 16px;
        align-items: center;
    }

    // .service__response-tab-title

    &__response-tab-title {
        color: $text3;
        padding: 12px 32px;
        border: 1px solid $subtitle;
        max-width: max-content;
        cursor: pointer;
        border-radius: 10px;
        transition: 0.5s;
        &._tab-active{
            border-color: $red;
            color: $red;
        }
        &:hover{
            transition: 0.5s;
            background-color: $hover-red;
            border-color: $hover-red;
            color: $white;
        }
        @media(max-width:$md4){
            padding: 12px 24px;
            width: 100%;
            display: flex;
            justify-content: center;
        }
        @media(max-width:360px){
            padding: 12px ;
        }
    }

    // .service__response-navigation

    &__response-navigation {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 0;
        gap: 28px;
        width: max-content;
        @media(max-width:$md3){
            display: none;
        }
        .swiper-button-prev , .swiper-button-next {
            position: relative!important;
            background: $background;
            box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
            // padding: 30px 30px;
            max-width: 64px;
            max-height: 64px;
            min-width: 64px;
            min-height:64px ;
            width: 100% !important ;
            height: 100% !important;
            border-radius: 100%;
            margin-top: 0 !important;
            transition: 0.2s;
            svg{
                width: 16px;
            }
            &:hover{
                background: $light-red;
                transition: 0.2s;
            }

        }
        .swiper-button-prev{
            svg{
                rotate: 180deg;
            }
        }

        .swiper-button-prev:after , .swiper-button-next:after{
            content: none !important;
        }
    }

    // .service__response-tab-content

    &__response-tab-content {
        .swiper {
            display: none;
                animation:fadeEffect .7s;
                &._tab-active{
                    display: block;
                    padding: 20px 0;
                }
        }

        .service__response-navigation{

        }


        .content {
        }
        .swiper-wrapper {
        }
        .swiper-slide {
        }
        .h4 {
        }
        .social {
        }
        .small-text {
        }
        .swiper-pagination {
            display: none;
        }
    }

    // .service__response-cart

    &__response-cart {
        background: #FFFFFF;
        width: 100%;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
    }

    // .service__response-video

    &__response-video {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-height: 384px;
        height: 100%;
    }

    // .service__response-iframe

    &__response-iframe {
        border-radius: 10px 10px 0 0;
        height: 384px;
        width: 100%;
        @media(max-width:$md3){
            height: 322px;
        }
    }

    // .service__response-cart-content

    &__response-cart-content {
        display: flex;
        flex-direction: column;
        padding: 24px;
    }

    // .service__response-cart-info

    &__response-cart-info {
        display: flex;
        gap: 16px;
        align-items: center;
    }

    // .service__response-cart-author

    &__response-cart-author {
        color: $title;
    }

    // .service__response-cart-links

    &__response-cart-links {
        display: flex;
        gap: 12px;
        align-items: center;
    }

    // .service__response-social

    &__response-social {
        min-width: 24px !important;
        min-height: 24px !important;
        max-height: 24px !important;
        max-width: 24px !important;
        svg{
            min-width: 24px !important;
            min-height: 24px !important;
            max-height: 24px !important;
            max-width: 24px !important;
        }
    }

    // .service__response-service

    &__response-service {
        margin-top: 8px;
        color: $subtitle;
    }

    // .service__response-cart-content-text

    &__response-cart-content-text {
        display: flex;
        flex-direction: column;
        gap: 40px;
        height: 100%;
        padding: 24px;
        justify-content: space-between;
    }

    // .service__response-cart-block

    &__response-cart-block {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    // .service__response-cart-rait

    &__response-cart-rait {
        display: flex;
        align-items: center;
        gap: 9px;
    }

    // .service__response-cart-text

    &__response-cart-text {
        color: $subtitle;
    }

    // .service__response-cart-doctor

    &__response-cart-doctor {
        padding: 8px 18px;
        background-color: $background;
        color: $text3;
        max-width: max-content;
        border: 1px solid transparent;
        transition: 0.3s;
        border-radius: 10px;
        &:hover{
            transition: 0.3s;
            border-color: $red;
            transform: scale(1.05);
        }
    }

    // .service__response-cart-text-service

    &__response-cart-text-service {
        background-color: $light-red;
        color: $red;
        padding: 8px 18px;
        border-radius: 10px;
        max-width: max-content;
        transition: 0.3s;
        border: 1px solid transparent;
        &:hover{
            transition: 0.3s;
            border-color: $red;
            transform: scale(1.05);
        }
    }

    @keyframes fadeEffect {
        from {opacity: 0;}
        to {opacity: 1;}
    }

    .swiper-slide{
        height: auto !important;
        .service__response-cart{
            height: 100%;
        }
    }
}







