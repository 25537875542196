.cookies {
    position: fixed;
    left: 20px;
    bottom: 20px;
    padding: 8px 8px 20px 20px;
    z-index: 10000;
    border-radius:20px;
    background: #fff;
    box-shadow: 0 0 8px rgba(0,0,0,0.2);
    max-width: 500px;
    width:100%;
    display: none;
    flex-direction: column;
    @media(max-width:$md3){
        max-width: 90%;
        left: 10px;
        bottom: 10px;
        padding: 8px 16px;
    }
    &.visible{
        display: flex;
    }
}
.cookies-close {
    position: relative;
    height: 20px;
    width: 20px;
    cursor: pointer;
    align-self: flex-end;
    &::after{
        content: '';
        position: absolute;
        rotate: 45deg;
        top: 1px;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: 100%;
        background: #000000;
        border-radius: 2px;
    }
    &::before{
        content: '';
        position: absolute;
        rotate: -45deg;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: 100%;
        background: #000000;
        border-radius: 2px;
    }
    @media(max-width:$md3){
        transform: translateX(8px);
    }
}
.cookies-title {
}
.cookies-text {
    padding: 12px 12px 12px 0;
    font-size: 16px;
    line-height: 150%;
    color: #82879A;
    @media(max-width:$md3){
        padding: 8px 0;
        font-size: 12px;
        line-height: normal;
    }
}
.cookies-buttons {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    @media(max-width:$md3){
        gap: 8px;
    }
}
.cookies-accept {
    flex: 1 1 280px;
    color: $red;
    padding: 12px;
    border: 1px solid $red;
    cursor: pointer;
    border-radius: 10px;
    transition: 0.5s;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: 40px;
    max-width: 150px;
    width: 100%;
    transition: 0.3s;
    &:hover{
        background: $red;
        color: #fff;
    }
}
.cookies-rules {
    flex: 1 1 100px;
    color: #515668;
    padding: 12px;
    max-width: 150px;
    width: 100%;
    border: 1px solid #82879A;
    cursor: pointer;
    border-radius: 10px;
    transition: 0.5s;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: 40px;
    transition: 0.3s;
    &:hover{
        border: $red;
        background: $red;
        color: #fff;
    }
}
