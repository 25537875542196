//COLORS
$white:#FFFFFF;
$background: #F2F4F8;
$red:#AB332F;
$light-red:#F2E0E0;
$title:#272B38;
$subtitle:#82879A;
$text3:#515668;
$border:#E3E6EE;
$hover-red: #811916;
$disabled:#BDC2D2;

$blackOpacity: rgba(33, 33, 33, 0.4);

// sizing
$maxContainerWidth: 1262px;
$paddingForMobileWrapper: 24px;

$defoultRadius: 10px;

// BREAKPOINTS
$md2: 991px;
$md3: 768px;
$md4: 479px;
