.publication-blog {
    // .publication-blog__container
    &.section-one-news{
        .service__content{
            width: 100%;
        }
    }

    &__container {
        display: flex;
        position: relative;
        gap: 33px;
        @media(max-width:$md2){
            flex-direction: column;
            align-items: center;
        }
        &.full-width{
            .service__content{
                width: 100%;
            }
        }
    }

    .service__left {
        position: sticky;
        height: calc(100vh - 200px);
        top: 160px;
        overflow: auto;
        width: 280px;
        display: flex;
        flex-direction: column;
        @media(max-width:1024px){
            height: auto;
            max-width: 600px;
            width: 100%;
            align-items: center;
            position: relative;
            border: 1px solid $border;
            border-radius: 10px;
            padding: 13.5px;
            top: 0;
        }
    }

    .service__left::-webkit-scrollbar {
        background: #D9D9D9;
        border-radius: 5px;
        width: 5px;
    }

    .service__left::-webkit-scrollbar-thumb {
        background: $red;
        border-radius: 5px;
    }


    .service__left-content{
        width: 100%;
        &.active{

            .service__left-list{
                display: flex !important;
            }
            .rotate-arrow__block{
                transform: rotate(0deg);
            }
        }
    }

    // .service__left-header

    .service__left-header {
        display: none;
        width:100%;
        align-items: center;
        justify-content: space-between;
        @media(max-width:1024px){
            display: flex;
        }
        .rotate-arrow__block{
            transition: 0.3s;
            transform: rotate(180deg);
        }
    }

    .service__left-subtitle{
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        color: $title;
        margin-bottom: 12px;
        @media(max-width:1024px){
            display: none;
        }
    }

    // .service__left-header-item

    &__left-header-item {
        display: flex;
        align-items: center;
        gap: 13.5px;
    }

    // .service__left-title

    .service__left-title {
        color: $red;
    }

    // .service__left-list

    .service__left-list {
        display: flex;
        flex-direction: column;
        gap: 12px !important;
        width: 100%;
        @media(max-width:1024px){
            margin-top: 24px;
            display: none !important;
        }
    }

    // .service__list-item

    .service__list-item {
        font-size: 14px !important;
        // font-weight: 300 !important;
    }

    // .service__list-anchor

    .service__list-anchor {
        font-size: 15px;
        line-height: 150%;
        color: $subtitle;
        &.active{
            color: $red;
        }
    }

    // .service__content
    .service__content{
        width: calc(100% - 280px - 50px);
        @media(max-width:1024px){
            width: 100%;
        }
    }
    // .publication-blog__right

    &__right {
        display: flex;
        flex-direction: column;
        gap: 56px;
        width: calc(100% - 280px - 50px);
        @media(max-width:1024px){
            width: 100%;
        }
        @media(max-width:$md3){
            max-width: 510px;
            margin: 0 auto;
            gap: 40px;
        }
        a{
            color: $red;
        }
        // p{
        //     margin-top: 24px ;
        //     font-size: 16px;
        //     line-height: 22px;
        //     color: $text3;
        //     @media(max-width:$md3){
        //         line-height: 19px;
        //         font-size: 14px;
        //     }
        // }
    }

    // .publication-blog__info

    &__info {
        display: flex;
        flex-direction: column;
        a{
            color: $red;
        }
        iframe{
            max-width: 100vw;
            // margin: 0px 16px;
            border-radius: 10px;
            @media(max-width:$md3){
                margin: 0;
                width: 100%;
            }
        }
        img{
            max-width: 100vw;
            margin: 0px 16px;
            border-radius: 10px;

            @media(max-width:$md3){
                margin: 0;
                width: 100%;
            }
        }
        h2{
            margin-bottom: 26px;
        }
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #515668;
        ul{
            display: flex;
            flex-direction: column;
            gap: 16px;
            li{
                list-style: outside;
                margin-left: 20px;
            }
        }

    }

    // .publication-blog__text

    &__text {
        font-size: 16px;
        line-height: 22px;
        color: $text3;
        @media(max-width:$md3){
            line-height: 19px;
            font-size: 14px;
        }
    }

    // .publication-blog__videos

    &__videos {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        gap: 32px;
        @media(max-width:$md3){
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
        }
    }

    // .publication-blog__video

    &__video {
        width: 100%;
        max-width: 385px;
        height: 338px;
        @media(max-width:$md3){
            max-width: 510px;
        }
        @media(max-width:$md4){
            height: 232px;
        }
    }

    // .publication-blog__frame

    &__frame {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    // .publication-blog__author

    &__author {
        display: flex;
        gap: 32px;
        padding: 16px;
        background-color: $background;
        border-radius: 10px;
        @media(max-width:$md3){
            display: none;
        }
    }

    // .publication-blog__author-img

    &__author-img {
        border-radius: 10px;
        max-width: 127px;
        height: 100%;
        width: 100%;
        img{
            border-radius: 10px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // .publication-blog__author-content

    &__author-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }

    // .publication-blog__author-header

    &__author-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    // .publication-blog__author-link

    &__author-link {
        display: flex;
        align-items: center;
        width: max-content;
        padding: 8px 18px 8px 16px;
        gap: 8px;
        background-color: $white;
        border-radius: 10px;
    }

    // .publication-blog__author-link-text

    &__author-link-text {
        color: $title;
        font-size: 16px;
        line-height: 150%;
    }

    // .publication-blog__author-share

    &__author-share {
        display: flex;
        gap: 12px;
        align-items: center;
    }

    // .publication-blog__author--share-text

    &__author-share-text {
        font-family: 'Inter';
        color: $text3;
        font-size: 16px;
        line-height: 19px;
    }

    // .publication-blog__author-about

    &__author-about {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    // .publication-blog__author-name

    &__author-name {
        color: $title !important;
    }

    // .publication-blog__author-description

    &__author-description {
        font-size: 16px;
        line-height: 22px;
        color: $subtitle;
    }

    // .publication-blog__buttons

    &__buttons {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        .prev{
            svg{
                rotate:180deg;
            }
        }
        @media(max-width:$md3){
            display: flex;
            flex-direction: column-reverse;
            width: 100%;
            align-items: center;
        }
    }

    // .publication-blog__button

    &__button {
        width: max-content;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 16px 40px;
        border: 1px solid #BDC2D2;
        border-radius: 10px;
        color: $title !important;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        max-width: 320px;
        width: 100%;
        justify-content: center;
        svg{
            transition: 0.3s;
        }
        transition: 0.3s;
        @media(max-width:$md3){
            max-width: 400px;
        }
        &:hover{
            background-color: $hover-red;
            border-color: $hover-red;
            color: $white !important;
            svg{
                path{
                    stroke: $white;
                }
            }
        }
    }
}

.disabled{
    &:hover{
        color: #BDC2D2 !important;
    }
}

