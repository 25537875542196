.gallery {

    overflow: hidden ;
    &__container {
    }
    // .gallery__desktop
    .doctors-main__filters-content{
        padding: 0px;
    }
    &__desktop {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 32px;
        padding: 32px 0 0 0 ;
        justify-content: space-between;
        @media(max-width:$md2){
            grid-template-columns: 1fr 1fr;
            justify-items: center;
            gap: 24px;
        }
        // @media(max-width:$md4){
        //     display: none;
        // }
        #replaceCode{


        }
    }

    &__pagination {
        // @media(max-width:$md4){
        //     display: none;
        // }
    }

    // .gallery__img

    &__img {
        max-width: 384px;
        width: 100%;
        max-height: 384px;
        img{
            height: 100%;
            width: 100%;
            border-radius: 10px;
            object-fit: cover;
        }

    }

    // &__mobile{
    //     display: none;
    //     @media(max-width:$md4){
    //         display: block;
    //     }
    //     .swiper {
    //         width: 100%;
    //         height: 100%;
    //       }

    //       .swiper-slide {
    //         text-align: center;
    //         font-size: 18px;
    //         background: #fff;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //       }

    //       .swiper-slide img {
    //         display: block;
    //         border-radius: 10px;
    //         width: 100%;
    //         height: 100%;
    //         object-fit: cover !important;
    //       }

    //       .swiper {
    //         width: 100%;
    //         height: 300px;
    //         margin-left: auto;
    //         margin-right: auto;
    //       }

    //       .swiper-slide {
    //         background-size: cover;
    //         background-position: center;
    //       }

    //       .mySwiper2 {
    //         height: 262px;
    //         padding: 15px 0;
    //         width: 100%;
    //       }

    //       .mySwiper {
    //         height: 110px !important;
    //         box-sizing: border-box;
    //         padding: 10px 0;
    //         overflow:visible;
    //       }

    //       .mySwiper .swiper-slide {
    //         width: 25%;
    //         height: 100%;
    //         opacity: 0.4;
    //       }

    //       .mySwiper .swiper-slide-thumb-active {
    //         opacity: 1;
    //       }

    //       .swiper-slide img {
    //         display: block;
    //         width: 100%;
    //         height: 100%;
    //         object-fit: cover;
    //       }

    //     .swiper-button-prev , .swiper-button-next {
    //         // position: relative!important;
    //         color: $red !important;
    //         background: $background;
    //         box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
    //         // padding: 30px 30px;
    //         max-width: 64px;
    //         max-height: 64px;
    //         min-width: 64px;
    //         min-height:64px ;
    //         width: 100% !important ;
    //         height: 100% !important;
    //         border-radius: 100%;
    //         margin-top: 0 !important;
    //         transition: 0.2s;
    //         svg{
    //             width: 16px;
    //         }
    //         &:hover{
    //             background: $light-red;
    //             transition: 0.2s;
    //         }
    //     }

    //     .swiper-button-disabled{
    //         opacity: 1 !important;
    //         svg{
    //             path{
    //                 fill: $subtitle;
    //             }
    //         }
    //     }

    //     .swiper-button-next{
    //         content: none !important;
    //         &:after{
    //             content: none !important;
    //         }
    //     }
    //     .swiper-button-prev {
    //         &:after{
    //             content: none !important;
    //         }
    //     }
    //     .swiper-button-prev{
    //         svg{
    //             rotate: 180deg;
    //         }
    //     }
    // }
}



.gallery-modal {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 30px 15px;
    background: rgba(0, 0, 0, 0.5);
    &.active-modal{
        display: flex;
    }
    // .gallery-modal__body

    &__body {
        width: auto;
        max-width: 90vh;
        max-height: 90vh;
        min-height: 200px;
        border-radius: 16px;
        overflow: hidden;
        background: $white;
        position: relative;
        padding: 35px 10px;
    }

    // .gallery-modal__close

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    // .gallery-modal__swiper

    &__swiper {
        .swiper-slide{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                display: block;
                margin: 0 auto;
                object-fit: contain;
                width: 100%;
                height: 100%;
                max-height: 80vh;
            }
            &.swiper-slide-active{
                display: block;
            }
            &.swiper-slide-next , &.swiper-slide-prev{
                height: 0px  !important;
                overflow: hidden !important;
            }
        }
    }
    .swiper-button-prev{
        min-width: 34px;
        max-width: 34px;
        min-height: 34px;
        max-height: 34px;
        width: 34px !important;
        height: 34px !important;
        border-radius: 100%;
        background-color: $background;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            rotate: 180deg;
        }
        &::after{
            content: none;
        }
    }

    .swiper-button-next{
        min-width: 34px;
        max-width: 34px;
        min-height: 34px;
        max-height: 34px;
        width: 34px !important;
        height: 34px !important;
        border-radius: 100%;
        background-color: $background;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after{
            content: none;
        }
    }
}
.thanks {

    // .thanks__close

    &__close {
    }
}
.swiper {
}
.swiper-wrapper {
}
.swiper-pagination {
}
.swiper-button-next {
}
.swiper-button-prev {
}

