.login {
    padding: 40px 0px 100px 0px;
    // .login__container

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    // .login__block

    &__block {
        background-color: $white;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
        max-width: 505px;
        width: 100%;
        padding: 40px 33px 57px 33px;
        @media(max-width:$md4){
            box-shadow: none;
            padding: 0px;
        }
    }

    // .login__header

    &__header {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: 12.86px;
    }

    // .login__logo

    &__logo {
        max-width: 84px;
        max-height: 25px;
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    // .login__title

    &__title {
        color: $title;
    }

    // .login__form

    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    // .login__form-content

    &__form-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    // .login__input-block

    &__input-block {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }

    // .login__input-label

    &__input-block {
        background-color: $background;
        border-radius: 10px;
        position: relative;
        svg{
            position: absolute;
            left: 28px;
            top: 50%;
            transform: translateY(-50%);


        }
        input::placeholder{
            color: #BDC2D2;
        }
    }

    // .form__input

    &__input {
        padding: 13.5px 24px 13.5px 64px;
        border-radius: 10px;
        color: $title;
        width: 100%;
        // font-family: 'Avenir';
        font-size: 18px;
        line-height: 27px;
        background-color: $background;
    }

    &__select-mask{
        background-color: $background;
        border-radius: 10px;
        max-height: 54px;
        height: 100%;
        // max-width: 85px;
        display: flex;
        align-items: center;
        width: 100%;
        .modal__select{
            margin-bottom: 0px;
            max-width: 85px;
            width: 100%;
            background-color: transparent;
        }
        .selectDropdown{
            margin-top: -10px;
            box-shadow: none ;
        }
        .selectBtn::after{
            right: 13px;
        }
        .modal__select-option{
            text-align: center;
        }
    }

    // .form__select

    &__select {
        // background-color: $background;
        width: 100%;
    }

    // .form__selectBtn

    &__selectBtn {
        background-color: $background !important;
        padding: 13.5px 24px !important;
        border-radius: 10px !important;
        color: #BDC2D2;
        width: 100%;
        // font-family: 'Avenir';
        font-size: 18px;
        line-height: 27px;
    }

    // .form__selectDropdown

    &__selectDropdown {
        width: 100%;
        background-color: $background;
        margin-top: -10px;
        box-shadow: none !important ;
    }

    // .form__select-option

    &__select-option {
        background-color: $background;
        color: $subtitle;
        width: 100%;
        // font-family: 'Avenir';
        font-size: 18px;
        line-height: 27px;
    }

    // .login__form-btn

    &__form-btn {
        max-width: 100%;
        width: 100%;
        padding: 16px;
    }

    &__check{
        display: flex;
        justify-content: space-between;
        gap: 34px;
        margin-top: 16px;
        @media(max-width:$md4){
            gap: 12px;
        }
    }

    &__check-input{
        max-width: 75px;
        border-radius: 10px;
        padding: 15px 24px;
        background-color: $background;
        border: 1px solid transparent;
        text-align: center;
        font-size: 30px;
        transition: 0.3s;
        color: $title;
        @media(max-width:$md4){
            max-width: 75px;
            padding: 15px 20px;
        }
        @media(max-width:360px){
            max-width: 60px;
            padding: 12px;
            font-size: 22px;
        }
    }
    &__check-input:focus{
        transition: 0.3s;
        transform: scale(1.03);
        border-color: $red;
    }

    &__block-return{
        display: flex;
        flex-direction: column;
        gap: 4px;

    }
    &__input-small{
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: $subtitle;
    }
    &__check-return{
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        text-decoration-line: underline;
        text-underline-offset: 5px;
        color: #2F80ED;
    }
}

