.popup-contact {
    position: fixed;
    top: 0;
    z-index: 9;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 15px;
    display: none;
    align-items: center;
    justify-content: center;
    background: #0000003a;
    &.active-popup{
        display: flex;
    }
    &__content {
        max-width: 400px;
        width: 100%;
        height: max-content;
        background: $white;
        padding: 30px;
        border-radius: 15px;
        .head{
            position: relative;
            justify-content: center;
            text-align: center;
            width: 100%;
        }
        .content{
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .header__top-numbers-init{
                display: flex !important;
                margin-bottom: 15px;
                justify-content: center;
                a{
                    color: $title;
                }
            }
        }
    }
    &__close {
        background: transparent;
        position: absolute;
        top: -15px;
        right: -10px;
        
    }
    &__btn{
        width: 100%;
        max-width: 100%;
        
    }
    &__soc{
        font-size: 16px;
        line-height: 130%;
        font-weight: 500;
        color: $title;
        display: flex;
        flex-direction: row-reverse;
        gap: 8px;
        align-items: center;
        margin: 0 auto;
        margin-top: 8px;
        &.viber-btn{
            svg{
                path{
                    fill: #5d56c6;
                }
            }
            &:hover{
                color: #5d56c6;
            }
        }
        &.telegram-btn{
            svg{
                path{
                    stroke:#3182c1;
                }
            }
            &:hover{
                color: #3182c1;
            }
        }
    }
    
}
