.swiper-partners {
    &__title{
        justify-content: center;
        align-items: center;
        display: flex;
        margin-bottom: 16px;
    }
    .swiper {
        padding: 40px 0;
        &:hover{
            .swiper-navigation{
                opacity: 1;
                visibility: visible;
                transition: 0.3s;
            }
        }
        @media(max-width:$md3){
            padding: 20px 0;
        }
    }
    .swiper-wrapper {
        cursor: grab !important;

    }
    .swiper-slide {
        max-width: 280px !important;
        border: 24px solid $background;
        background-color: $background;
        border-radius: 10px;
        // max-height: 190px;
        // height: 100%;
        // width: 100% !important;
        @media(max-width:$md3){
            border: 16px solid $background;
            max-height: 140px;
            height: 100%;
            max-width: 220px !important;
        }
    }
    &__img {
        max-height: 140px;
        width: 100%;
        height: 100%;

        img{
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }

    .swiper-navigation {
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        position: relative;
        gap: 80px;
    }
    .swiper-button-prev , .swiper-button-next {
        position: relative!important;
        width: max-content !important;
        background: $background;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        padding: 30px 30px;
        border-radius: 10px;
        margin-top: 0 !important;
        transition: 0.2s;
        &:hover{
            scale: 1.2;
            transition: 0.2s;
        }
    }

    .swiper-button-prev:after , .swiper-button-next:after{
        font-size: 30px !important;
        font-weight: 800;
        color: $red !important;
    }
    //  {
    //     position: relative!important;
    //     width: max-content !important;
    // }

    // .swiper-partners__img


}

.partners-line{
    display: flex;
    gap: 20px;
}

