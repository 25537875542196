.modal-window {
    width: 100vw;
    height: 100vh;
    background-color: $blackOpacity;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99;
    font-family: Noto Sans;

    &__container {
        width: 632px;
        height: auto;
        padding: 44px;
        border-radius: 10px;
        background-color: $white;

        @media screen and (max-width: $md2) {
            max-width: calc(100% - $paddingForMobileWrapper * 2);
        }
    }
}

// only for language buttons
.language-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    &__buttons-block {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        width: 100%;

        button {
            width: 100%;
            text-align: center;
            border: 1px solid $border;
            color: $text3;
            padding: 12px 32px;
            border-radius: 10px;
            background-color: transparent;

            &.red {
                border-color: $red;
                color: $red;
            }
        }
        @media(max-width:$md3){
            grid-template-columns: 1fr;
        }
    }

    p {
        color: $text3;
        text-align: center;
    }
}
