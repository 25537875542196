.form {
    font-weight: 300;
    font-size: 16px;

    // .form__container

    &__container {
    }

    // .form__form

    &__form {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    // .form__block

    &__block {
        padding: 32px;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        border-radius: 10px;
        background-color: $background;
        @media(max-width:$md3){
            flex-direction: column;
        }
        // &:not(:nth-child(1)){
        //     svg{
        //         path{
        //             stroke: $subtitle;
        //         }
        //     }
        // }
    }

    // .form__left

    &__left {
    }

    // .form__title

    &__title {
        color: $title;
    }

    // .form__right

    &__right {
        display: flex;
        flex-direction: column;
        gap: 32px;
        max-width: 560px;
        width: 100%;
        @media(max-width:$md3){
            max-width: 100%;
        }
    }

    // .form__mini-block

    &__mini-block {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &:nth-child(n+1){


        }
    }

    // .form__input-title

    &__input-title {
        color: $title;
    }

    // .form__input-block

    &__input-block {
        position: relative;
        svg{
            position: absolute;
            left: 28px;
            top: 50%;
            transform: translateY(-50%);
        }
        input::placeholder{
            color: $subtitle;
        }
        @media(max-width:$md4){
            svg{
                left: 12px;
            }

        }
    }
    .phone-mask3{
        padding-left: 16px !important ;
        &::placeholder {
            color: $subtitle;
        }
    }
    .modal{

        &__input {
            padding: 13.5px 24px 13.5px 64px;
            border-radius: 10px;
            // color: #BDC2D2!important;
            width: 100%;
            font-size: 18px;
            line-height: 27px;
            background-color: $white !important;
            @media(max-width:$md4){
            font-size: 14px;
            padding: 12px 15px 12px 30px;
            }

        }


        &__select-mask{
            background-color: $white !important;
        }
        // .form__select

        &__select {
            background-color: $white !important;
            width: 100%;
            border-radius: 10px;
        }

        // .form__selectBtn

        &__selectBtn {
            background-color: $white !important;
            padding: 13.5px 24px !important;
            border-radius: 10px !important;
            color: #BDC2D2;
            width: 100%;
            font-size: 18px;
            line-height: 27px;
        }

        // .form__selectDropdown

        &__selectDropdown {
            background-color: $background;
        }

        // .form__select-option

        &__select-option {
            background-color: $background;
            color: $subtitle;
            width: 100%;
            font-size: 18px;
            line-height: 27px;
        }
    }
    // .form__input

    &__input {
        padding: 13.5px 24px 13.5px 64px;
        border-radius: 10px;
        color: $title !important;
        width: 100%;
        font-size: 16px;
        font-weight: 300;
        line-height: 27px;
        @media(max-width:$md4){
            font-size: 14px;
            padding: 12px 15px 12px 45px;
        }
        &::placeholder {
            color: $subtitle;
        }
    }

    // .form__select

    &__select {
        background-color: $background;
        width: 100%;

    }

    // .form__selectBtn

    &__selectBtn {
        background-color: $background;
        padding: 13.5px 24px !important;
        border-radius: 10px !important;
        // color: #BDC2D2;
        width: 100%;
        font-size: 16px;
        line-height: 27px;
        color: $title;

        &[data-type="placeholder"] {
            color: $subtitle;
        }

        @media(max-width:$md4){
            font-size: 14px;
            padding: 12px 15px;
        }
    }

    // .form__selectDropdown

    &__selectDropdown {
        background-color: $background;
    }

    // .form__select-option

    &__select-option {
        background-color: $background;
        color: $subtitle;
        width: 100%;
        font-size: 18px;
        line-height: 27px;
    }

    // .form__input-review

    &__textarea {
        resize: none;
        display: flex;
        align-items: flex-start;
        position: relative;
        padding: 13.5px 24px;
        border-radius: 10px;
        color: $title;
        width: 100%;
        font-size: 16px;
        font-weight: 300;
        line-height: 27px;
        height: 176px;
        &::placeholder {
            color: $subtitle;
        }
    }

    &__send{
        margin: 20px auto;
    }
    input:focus{
        color: $title !important;
    }

}
.container {
}
.h3 {
}
.text {
}
.select {
}
.selectBtn {
}
.selectDropdown {
}
.option {
}
.rating {

    // .rating__stars

    &__stars {
    }

    // .rating__input

    &__input {
    }

    // .rating__input-1

    &__input-1 {
    }

    // .rating__input-2

    &__input-2 {
    }

    // .rating__input-3

    &__input-3 {
    }

    // .rating__input-4

    &__input-4 {
    }

    // .rating__input-5

    &__input-5 {
    }

    // .rating__label

    &__label {
    }

    // .rating__star

    &__star {
        max-width: 21px;
        width: 100%;
        min-width: 21px;
        max-height: 21px;
        min-height: 21px;
        height: 100%;
    }

    // .rating__star-ring

    &__star-ring {
    }

    // .rating__star-stroke

    &__star-stroke {
    }

    // .rating__star-fill

    &__star-fill {
    }

    // .rating__star-line

    &__star-line {
    }
}
