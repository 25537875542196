.swiper-main {
    // display: none;
    // .swiper-main__img
    // height: 530px;
    // min-height: 530px;
    // @media(max-width:$md3){
    //     height: 460px;
    // }
    height: auto;
    .swiper {
        position: relative;
        z-index: 1;
    }
    .swiper-main-navigations-custom{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50% , -50%);
        max-width: 1465px;
        width: 100%;
        height: 100px;
        z-index: 1;
        @media(max-width:$md2){
            display: none;
        }
    }
    .swiper-wrapper {
        padding-bottom: 36px;
        display: grid;
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(99, 1fr);
    }
    .modalbtn{
        display: none !important;
    }
    .swiper-slide {
        position: relative;

        height: 100%;
        display: flex;
        align-items: flex-end;
        // @media(max-width:$md3){
        //     max-height: 345px;
        //     min-height: 345px;
        // }
        .main2{
            width: 100%;
            height: 100%;
            // max-height: 446px;
            // min-height: 446px;
            // @media(max-width:$md3){
            //     max-height: 345px;
            //     min-height: 345px;
            // }
        }
    }

    &__img {
        position: absolute;
        max-width: 100vw;
        width: 100%;
        max-height: 546px;
        min-height: 546px;
        height: 100%;
        img{
            position: absolute;
            max-width: 100vw;
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
        @media(max-width:$md3){
            max-height: 345px;
            min-height: 345px;
        }
    }

    // .swiper-main__content

    &__content {
        display: flex;
        // align-items: end;
        position: relative;
        z-index: 1;
        flex-direction: column;
        gap: 24px;
        max-width: 550px;
        padding-left: 15px;
    }

    // .swiper-main__block
    &__block{
        background: rgba(255, 255, 255, 0.75);
        border-radius: 0 100px 0 0;
        width: 53%;
        display: flex;
        align-items: flex-end;
        position: relative;
        justify-content: flex-end;
        padding: 40px 104px 40px 0;
        @media(max-width:$md2){
            width: 100%;
            justify-content: flex-start;
            padding: 40px 24px 40px 0;
        }
    }



    // .swiper-main__content-title

    &__content-title {
        // max-width: 50%;
    }

    // .swiper-main__content-info

    &__content-info {
        position: relative;
        display: flex;
        align-items: center;
        gap: 32px;
        @media(max-width:$md3){
            gap: 24px;
        }
        // max-width: 50%;
    }

    // .swiper-main__content-border

    &__content-border {
        height: 50%;
        min-width: 24px;
        position: relative;
        top: 0;
        border-bottom: 1px solid $subtitle;
    }

    // .swiper-main__content-text

    &__content-text {
        font-size: 16px;
        line-height: 130%;
        color: $subtitle;
        // @media(max-width:$md3){
        //     font-size: 16px;
        // }
    }
    .swiper-pagination {
    }
    .swiper-pagination-bullet {
        background-color: #ee2626 !important;
    }
    .swiper-pagination-bullet-active{
        background-color: $red !important;
    }
    .swiper-button-prev{
        svg{
            rotate: 180deg;
        }
    }
    .swiper-button-prev , .swiper-button-next{
        min-width: 64px;
        max-width: 64px;
        min-height: 64px;
        max-height: 64px;
        width: 64px !important;
        height: 64px !important;
        border-radius: 100%;
        background-color: $background;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after{
            content: none;
        }
        @media(max-width:$md2){
            display: none;
        }
    }
}



