.price-c {
    position: relative;
    min-height: 600px;
    @media(max-width:$md3){
        min-height: 367px;
    }
    // .price-c__img

    &__img {
        position: absolute;
        max-width: 100vw;
        width: 100%;
        min-height: 600px;
        height: 100%;
        img{
            position: absolute;
            max-width: 100vw;
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
        @media(max-width:$md3){
            min-height: 367px;
        }
    }

    // .price-c__block

    &__block {
        background: rgba(255, 255, 255, 0.6);
        width: 60%;
        height: 100%;

        display: flex;
        position: absolute;
        @media(max-width:$md2){
            width: 100%;
        }
    }

    // .price-c__container

    &__container {
        padding: 100px 15px;
        position: relative;
        z-index: 1;
        min-height: 600px;
        justify-items: center;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 62px;
        @media(max-width:$md3){
            padding:64px 15px;
            gap: 32px;
            min-height: 367px;
        }
        @media(max-width:360px){
            padding:32px 15px;
            gap: 18px;
        }
    }

    // .price-c__content

    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 24px;
        max-width: 600px;
    }

    // .price-c__content-title

    &__content-title {
        font-weight: 700 !important;
        font-size: 38px;
        line-height: 110%;
        color: #272B38;
        @media(max-width:$md2){
            font-weight: 600;
            font-size: 18px;
        }
    }

    // .price-c__content-info

    &__content-info {
    }

    // .price-c__content-text

    &__content-text {
        font-size: 18px;
        line-height: 130%;
        color: $text3;
        display: flex;
        flex-direction: column;
        gap: 16px;
        @media(max-width:$md3){
            font-size: 14px;
        }
        ul , ol{
            display: flex;
            flex-direction: column;
            gap: 16px;
            li{
                list-style: inside;
            }
        }
        h4{
            font-weight: 500;
            font-size: 20px;
            line-height: 27px;
            color: #272B38;
        }
        @media(max-width:$md2){
            font-size: 14px;
            line-height: 130%;
            h4{
                font-size: 18px;
                line-height: 24px;
            }
        }
    }

    // .price-c__link

    &__link {
    }
}

