.action-prew {

    // .action-prew__container

    &__container {
        display: flex;
        flex-direction: column;
        gap: 56px;
        align-items: center;
    }

    // .action-prew__title

    &__title {
        color: $title;
    }

    // .action-prew__content

    &__content {
        display: flex;
        justify-content: space-between;
        gap: 32px;
        @media(max-width:$md2){
            flex-direction: column;
            align-items: center;
        }
    }

    // .action-prew__cart

    &__cart {
        max-width: 592px;
        width: 100%;
        display: flex;
        background: $white;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
        @media(max-width:$md4){
            flex-direction: column;
        }
    }

    // .action-prew__cart-left

    &__cart-left {
        min-width: 296px;
        width: 100%;
        max-width: 296px;
        // height: 100%;
        position: relative;
        @media(max-width:$md2){
            min-width: 100px;
        }
        @media(max-width:$md4){
            max-width: 100%;
            height: 246px;
        }
    }

    // .action-prew__cart-img

    &__cart-img {
        // position: absolute;
        width: 100%;
        height: 100%;
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 10px 0 0 10px;
        }
        @media(max-width:$md4){
            img{
                border-radius: 10px 10px 0 0;
            }

        }
    }


    &__cart-term-block {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        gap: 16px;
        // width: 100%;
        // height: 100% !important;
        padding: 24px;
        @media(max-width:$md4){
            top: auto;
            bottom: 0 !important;
            padding: 16px;
        }
    }
    // .action-prew__cart-term

    &__cart-term {
        // position: relative;
        // top: 0;
        width: max-content;
        padding: 8px 16px;
        background: $white;
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 9.75px;
    }
    &__cart-data {
        // position: relative;
        // top: 0;
        // width: max-content;
        padding: 8px 16px;
        background: $white;
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 9.75px;
    }

    // .action-prew__cart-right

    &__cart-right {
        padding: 24px 16px 24px 24px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        justify-content: space-between;
    }

    &__cart-right-block{
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    // .action-prew__cart-service

    &__cart-service {
        font-size: 16px;
        line-height: 22px;
        color: $subtitle;
    }

    // .action-prew__cart-description

    &__cart-description {
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        color: $title;
    }

    // .action-prew__cart-link

    &__cart-link {
        width: max-content;
        padding: 16px 32px;
        margin-top: 20px;
    }

    // .action-prew__link

    &__link {

        // border-radius: 10px;
    }
}
