
.contact {


    // .contacts__container

    &__container {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    // .contacts__map

    &__map {
        display: flex;
        flex-direction: column;
        gap: 56px;

    }

    // .contact__map-frame

    &__map-frame {
        border-radius: 10px !important;
        width: 100%;
        height: 734px;
        @media(max-width:$md2){
            height: 534px;
        }
        @media(max-width:$md3){
            height: 334px;
        }
        @media(max-width:$md4){
            height: 184px;
        }
    }

    // .contact__map-block

    &__map-block {
        display: flex;
        gap: 26.75px;
        padding: 26.75px 18.75px;
        background-color: $background;
        border-radius: 10px;
        align-items: center;
        svg{
            min-width: 27.5px;
            max-width: 27.5px;
        }
    }

    // .contact__map-text

    &__map-text {
        color: $text3;
    }


    // .contact__ways
    &__ways {
        display: flex;
        flex-direction: column;
        gap: 37px;
    }
    // .contact__tabs

    &__tabs {
        display: flex;
        gap: 32px;
        align-items: center;

        .reviews__label{
            max-width: 248px;
            text-align: center;
            .reviews__input-text{
                justify-content: center;
            }
        }
        @media(max-width:575px){
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            .reviews__label{
                max-width: 320px;
            }
        }
    }

    // .contacts__bottom

    &__bottom {
        display: flex;
        gap: 32px;

        @media(max-width:$md2){
            flex-direction: column;
            align-items: center;
        }
    }

        // .contact__bottom-img

    &__bottom-img {
        height: 316px;
        border-radius: 10px;
        max-width: 488px;
        width: 100%;
        img{
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
        }
    }

    // .contact__bottom-list

    &__bottom-list {
        display: flex;
        flex-direction: column;
        gap: 32px;
        @media(max-width:$md2){
            width: 100%;
        }
    }

    // .contact__bottom-title

    &__bottom-title {
        color: $title;
        @media(max-width:$md2){
            text-align: center;
            width: 100%;
        }

    }

    // .contact__bottom-block

    &__bottom-block {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    // .contact__bottom-item

    &__bottom-item {
        display: flex;
        align-items: flex-start;
        gap: 16px;
    }

    // .contact__bottom-svg

    &__bottom-svg {
        width: 24px;
        height: 24px;
        img{
            width: 24px;
            height: 24px;
            object-fit: contain;
        }
    }

    // .contact__bottom-text

    &__bottom-text {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    // .contact__bottom-subtitle

    &__bottom-subtitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        color: $title;
    }

    // .contact__bottom-info

    &__bottom-info {
        font-size: 16px;
        line-height: 22px;
        color: $text3;
    }


    // .contact__block

    &__block {
        max-width: 390px;
        width: 100%;
        background: $white;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
    }

    // .contact__block-header

    &__block-header {
        background-color: $background;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 11px;
        border-radius: 10px;
    }

    // .contact__list

    &__list {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding: 16px 24px;
        justify-content: space-between;
    }

    // .contact__list-item

    &__list-item {
        color: #515668;
        font-size: 18px;
        font-weight: 500;
        line-height: 150%;
        transition: .3s;
    }

    // .contact__list-text

    &__list-text {
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        width: 100%;
        text-align: left;
        color: $text3;
        transition: 0.3s;
        @media(max-width:575px){
            font-size: 16px;
        }
        &.center {
            text-align: center !important;
        }
    }

    // .contact__list-link

    &__list-link {
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        text-align: center;
        color: $text3;
        display: flex;
        align-items: center;
        gap: 8px;
        transition: 0.3s;
        &:hover{
            .contact__svg-background{
                transform: scale(1.04);
            }
            color: $red;
            p{
                color: $red;
            }
        }
        @media(max-width:575px){
            font-size: 16px;
        }
    }
    .gap{
        gap: 32px;
    }
    .centered-text{

        width: 100px;
        text-align: center;
    }
    &__svg-background{
        transition: 0.3s;
        min-width: 56px;
        max-width: 56px;
        width: 100%;
        min-height: 56px;
        max-height: 56px;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: $background;
        border-radius: 100%;
    }
}
.no-centre {
    // align-items: flex-start;
    gap: 8px;
    // width: max-content;
    @media(max-width:575px){
        width: 100%;
        align-items: center;
    }
}



// .contact__top

.contact__top {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 32px;
    @media(max-width:$md2){
        grid-template-columns: 1fr 1fr;
        justify-items: center;
    }
    @media(max-width:575px){
        grid-template-columns: 1fr;
    }
    .contact__block{
        max-width: 100%;
        height: 100%;
        &:first-child{
            max-width: 100%;
            display: flex;
            flex-direction: column;
            grid-column-start: 1;
            grid-column-end: 3;
        }
        &:nth-child(2){
            max-width: 100%;
            display: flex;
            flex-direction: column;
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 3;
        }
        &:nth-child(3){
            max-width: 100%;
            display: flex;
            flex-direction: column;
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 3;
            grid-column-end: 5;
        }
        &:nth-child(4){
            max-width: 100%;
            display: flex;
            flex-direction: column;
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 3;
            grid-column-end: 5;
        }
        @media(max-width:$md2){
            &:nth-child(2){
                max-width: 100%;
                display: flex;
                flex-direction: column;
                grid-row-start: 2;
                grid-row-end: 3;
                grid-column-start: 1;
                grid-column-end: 3;
            }
            &:nth-child(3){
                max-width: 100%;
                display: flex;
                flex-direction: column;
                grid-row-start: 3;
                grid-row-end: 4;
                grid-column-start: 1;
                grid-column-end: 3;
            }
            &:nth-child(4){
                max-width: 100%;
                display: flex;
                flex-direction: column;
                grid-row-start: 4;
                grid-row-end: 5;
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }
        @media(max-width:575px){
            max-width: 100%;
            &:first-child{
                grid-column-end: 2;
            }
            &:nth-child(2){
                grid-column-end: 2;
            }
            &:nth-child(3){
                grid-column-end: 2;
            }
            &:nth-child(4){
                grid-column-end: 2;
            }
        }
    }

}


.contact-bottom-content{
    display: none;
    &.active{
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}






.contact__map-frame{
    .i4ewOd-pzNkMb-tJHJj{
        display: none !important;
    }
}
