.advantages {

    // .advantages__container

    &__container {
        display: flex;
        flex-direction: column;
        gap: 56px;
        align-items: center;
    }

    // .advantages__title

    &__title {
        color: $title;
        @media(max-width:$md4){
            font-size: 20px;
        }
    }

    // .advantages__content

    &__content {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 32px;
        @media(max-width:$md3){
            grid-template-columns: 1fr 1fr;
            justify-items: center;
        }
        @media(max-width:$md4){
            grid-template-columns: 1fr;
            gap: 24px;
        }
    }

    // .advantages__block

    &__block {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        background-color: $background;
        border-radius: 10px;
        @media(max-width:$md4){
            flex-direction: row;
        }
    }

    // .advantages__gif

    &__gif {
        max-width: 96px;
        max-height: 96px;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    // .advantages__text

    &__text {
        text-align: center;
        color:$title;
        @media(max-width:$md3){
            text-align: left;
        }
    }

    &__link{
        margin: 0 auto;
    }
}

