
.header{

    // .header__list-level.pushNav.js-topPushNav:has(+ li ul.header__list-level.pushNav.pushNav_level.js-pushNavLevel.isOpen){
    //     right: 0;
    // }

    // .pushNav:has(.isOpen) {
    //     .isOpen {
    //         // right: -600px;
    //     }
    // }

    // .header__list-level:has(+ li .header__list-level.isOpen) {
    //     right: 0;
    // }


    &__list-level{
        padding: 0;
        margin: 0;
        list-style-type: none;
        height: 100%;
        position: fixed;
        top: 0;
        z-index: 100;
        overflow-x: hidden;
        transition: ease-in-out 0.5s;
        max-width: 350px;
        width: 100%;
        right: -350px;
        background: $white;
        &.isOpen{
            right: 0;
        }
        li{
            padding: 8px 30px;
        }
        @media(max-width:$md4){
            max-width: 100%;
            right: -600px;
        }
    }

    &__close-level{
        cursor: pointer;
        padding: 30px !important;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        gap: 14px;
        svg{
            &:hover{
                transform: scale(1.5);
            }
        }
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
    }
    &__open-level{
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 14px;

    }
    &__burger{
        display: none !important;
        @media(max-width:$md2){
            display: block !important;
        }
    }
    .close-first-level{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

    }


    // .header__mobile-contact
    .header__mobile-buttons-new{
        display: none;
        padding: 0 30px;
        @media(max-width:$md2){
            display: block;
        }
    }
    &__mobile-contact {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-items: center;
        align-items: center;
    }
   
    // .header__mobile-btn

    &__mobile-btn {

        margin-top: 10px;
        &:first-child{
            margin-top: 20px;
        }
        &:last-child{
            // margin-bottom: -26px;
        }
    }

    // .header__top-socials

    &__top-socials {
        margin-top: 8px;
    }


    // .header__top-social

    &__top-social {
    }

    // .header__top-location

    &__top-location {
        align-items: center;
        gap: 14px;
    }

    // .header__mobile-phone

    &__mobile-phone {
        display: flex;
        align-items: center;
        gap: 14px;
    }

}

.margin-top{
    margin-top: 40px;
}

.flex {
    display: flex !important;
}

.pushNavIsOpen {
    overflow: hidden;
    height: 100%;
}

.textLevel{
    // padding: 8px 30px;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    color: $title;
    display: flex;
    align-items: center;
    gap: 15px;

    &.active {
        color: #AB332F;
    }
}

.textSubLevel{
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: $subtitle;

    &.active {
        color: #AB332F;
    }
}


.screen {
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 0;
    z-index: 99;
    top: 0;
    bottom: 0;;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.pushNavIsOpen .screen {
    height: 100%;
    opacity: 1;
}
