.quote-third {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-end;
    width: 100%;
    &__content {
        display: flex;
        gap: 24px;
        align-items: flex-start;
        border-radius: 10px;
        background: #F2F4F8;
        padding: 16px 32px;
        width: 100%;
        p{
            font-size: 18px !important;
            font-style: italic;
            font-weight: 400;
            line-height: 150% !important;
            color: $title !important;  
            margin-top: 30px;
        }
        @media(max-width:$md2){
            p{
                margin-top: 12px;
            }
        }
    }
    &__icon {
        font-size: 94px;
        font-style: italic;
        font-weight: 500;
        line-height: normal; 
        color: $red;
        @media(max-width:$md2){
            font-size: 64px;
        }
    }
    &__author {
        font-size: 18px !important;
        font-style: normal;
        font-style: italic;
        font-weight: 500 !important;
        line-height: 150% !important;
    }
}
