.reviews {

    // .reviews__container

    &__container {
        position: relative;
        display: flex;
        gap: 64px;
        @media(max-width:$md3){
            flex-direction: column;
            gap: 16px;
        }
        #replaceCode{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
    }

    // no delete
    &__block-contents{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 14px;

        .response__cart {
            min-height: 100%;
        }
    }
    &__filters-reset{
        display: none;
        margin: 0 auto;
        max-width: 440px;
        width: 100%;
        padding: 12px 32px ;
        gap: 13px;
        border: 1px solid $border;
        background-color: transparent;
        border-radius: 10px;
        text-align: center;
        color: $text3;
        font-size: 16px;
        @media(max-width:$md3){
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__filters-init{
        display: none;
        margin: 0 auto;
        max-width: 440px;
        width: 100%;
        padding: 12px 32px ;
        gap: 13px;
        border: 1px solid $border;
        background-color: transparent;
        border-radius: 10px;
        text-align: center;
        color: $text3;
        font-size: 16px;
        @media(max-width:$md3){
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__filters-init-text{

    }
    // .reviews__filters

    &__filters {
        overflow: hidden;

        @media(max-width:$md3){
            align-items: center;
            gap: 24px;
            position: fixed;
            z-index: 2;
            right: -800px;
            max-height: 100vh;
            height: 100%;
            top: 0;
            transition: 0.5s;
            padding: 100px 0px  50px 0px;
        }
        &.filtersActive{
            right: 0px;
            z-index: 999999;
            padding: 15px 15px 0px 15px !important;
            // padding: 80px 15px 0 15px;
            width: 100%;
            overflow-y: auto;
            background-color: #FFFFFF;
        }

    }

    .reviews_filters-set{
        flex-direction: column;
        width: calc(100% + 30px);
        align-items: center;
        gap: 8px;
        button{
            width: 100%;
            max-width: 440px;
        }
    }

    &__filters-close{
        display: none;
        gap: 16px;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid $border;
        padding-bottom: 16px;
        @media(max-width:$md3){
            display: flex;
            padding-left: 15px;
        }
    }

    &__filters-content{
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 48px;
        max-width: 250px;
        width: 100%;
        min-width: 240px;
        @media(max-width:$md3){
            margin: 0 auto;
            max-width: 400px;
            align-items: center;
            gap: 56px;
        }
    }
    // .reviews__tab

    &__tab {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
    }

    // .reviews__tab-block-content

    &__tab-block-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
    }

    // .reviews__tab-title

    &__tab-title {
        color: $text3;
        padding: 12px 32px;
        border: 1px solid $border;
        width: 100%;
        cursor: pointer;
        border-radius: 10px;
        transition: 0.5s;

        &._tab-active{
            border-color: $red;
            color: $red;
        }
        &:hover{
            background-color: $light-red;
            border-color: $red;
            color: $red;
        }
        @media(max-width:$md3){
            text-align: center;
            font-size: 16px !important;
        }
    }

    // .reviews__filters-section

    &__filters-section {
        display: flex;
        flex-direction: column;
        gap: 16px;
        @media(max-width:$md3){
            width: 100%;
        }
    }

    // .reviews__filters-title

    &__filters-title {
        color: $title;
    }

    // .reviews__filters-content

    &__filters-section-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-bottom: 24px;
        @media(max-width:$md3){
            width: 100%;
        }
    }

    // .reviews__button

    &__button {
    }

    // .reviews__label

    &__label {
        width: 100%;

        &.active .reviews__input-text{
            color: $red;
            border-color: $red;
            &:hover{

                background-color: $hover-red;
                border-color: $hover-red;
                color: $white;
            }
            @media(max-width:$md3){
                &:hover{
                    border-color: $red;
                    color: $red;
                    background-color:$white;
                }
            }
        }

        //todo: потрібно видалити після ого як відгуки перепишемо на ЧПУ
        input:checked + .reviews__input-text {
            color: $red;
            border-color: $red;
            &:hover{

                background-color: $hover-red;
                border-color: $hover-red;
                color: $white;
            }
            @media(max-width:$md3){
                &:hover{
                    border-color: $red;
                    color: $red;
                    background-color:$white;
                }

            }
        }

    }

    input[type="radio"]:checked+label{
        border-color: $red;
        &:hover{

            background-color: $hover-red;
            border-color: $hover-red;
            color: $white;
        }
        @media(max-width:$md3){
            &:hover{
                border-color: $red;
                color: $red;
                background-color:$white;
            }

        }
    }
    // .reviews__input

    &__input {
        position:absolute;
        visibility:hidden;
        height:0px;
        width:0px;
    }

    // .reviews__input-text

    &__input-text {
        font-size: 18px;
        line-height: 150%;
        color: $text3;
        padding: 12px 32px;
        border: 1px solid #E3E6EE;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        transition: 0.5s;
        &:hover{

            background-color: $hover-red;
            border-color: $hover-red;
            color: $white;
        }
        @media(max-width:$md3){
            justify-content: center;
            width: 100%;
            font-size: 16px;
            text-align: center;
            padding: 12px ;
        }
    }

    // .reviews__content

    &__content {
        // display: none;
        flex-direction: column;
        gap: 24px;
        animation:fadeEffect .7s;
        &._tab-active{
            display: flex;
        }
    }

    .for-video{
        width: 100%;
        // display: none;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 32px;
        grid-template-rows: max-content;
        @media(max-width:1060px){
            display: flex;
            flex-direction: column;
            align-items: center;
        }

    }

    &__cart-video{
        background-color: $white;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
        max-width: 440px;
        width: 100%;
        height: 100%;
        .response__cart-info {
            height: auto;
            .fb_avatar {
                width: 48px;
                height: 48px;
                min-width: 48px;
                min-height: 48px;
                object-fit: cover;
                border-radius: 99px;
                overflow: hidden;
            }
        }
    }

    &__video{
        max-width: 440px;
        width: 100%;
        border-radius: 10px 10px 0 0;
        height: 386px;
        @media(max-width:$md3){
            height: 300px;
        }
    }

    &__video-frame{
        border-radius: 10px 10px 0 0;
        // max-width: 440px;
        width: 100%;
        height: 100%;
        // object-fit: cover;
    }

    &__service{
        color: $subtitle;
    }

    // .reviews__cart-block

    &__cart-block {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        &:nth-child(1){
            justify-content: space-between;
        }
        @media screen and (max-width: $md3) {
            flex-direction: column;
        }

    }

    // .reviews__cart-text-service
    // .reviews__cart-doctor
    &__cart-doctor,
    &__cart-text-service {
        display: flex;
        gap: 8px;
        background-color: $light-red;
        color: $red;
        padding: 8px 18px;
        border-radius: 10px;
        transition: 0.3s;
        border: 1px solid transparent;
        width: max-content;
        max-width: 100%;
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.link {
            display: inline;
            &:hover{
                transition: 0.3s;
                border-color: $red;
                transform: scale(1.02);
            }
        }

        &.no-link {
            display: inline;
        }

        &_none {
            opacity: 0.5;
        }

        .name {
            max-width: 100%;
            width: max-content;
            overflow: hidden;
            text-wrap: nowrap;
            text-overflow: ellipsis;
        }
    }

    //.reviews__cart-doctor
    &__cart-doctor {
        background-color: $background;
        color: $text3 !important;
    }

    @keyframes fadeEffect {
        from {opacity: 0;}
        to {opacity: 1;}
    }

    .none-reviews{
        text-align: center;
        width: 100%;
        color: $title;
    }

    &__date {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 4px;
        color: $text3;

        .date {
            display: flex;
            align-items: center;
            gap: 4px;
        }

        &_for-slider {
            justify-content: space-between;
            .read-more {
                transition: .3s;
                cursor: pointer;
                &:hover {
                    color:$red;
                    transform: scale(1.05);
                }
            }
        }
    }
}



.reviews {

    // .reviews__statistic

    &__statistic {
        display: flex;
        gap: 24px;
        padding: 24px;
        background: #FFFFFF;
        width: 100%;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
        margin-bottom: 14px;
    }

    // .reviews__statistic-head

    &__statistic-head {
        display: flex;
        flex-direction: column;
        width: max-content;
        justify-content: space-between;
        gap: 16px;
    }

    // .reviews__statistic-head-numero

    &__statistic-head-numero {
        font-size: 52px;
        color: $red;
    }

    // .reviews__statistic-stars

    &__statistic-stars {
        display: flex;
        gap: 2px;
        align-items: center;
        flex-wrap: nowrap;
        .reviews__statistic-star{
            width: max-content;
            svg{
                height: 14px;
                width: 14px;
                path{
                    fill: $red;
                }
            }
            &.half-star{
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 50%;
                    background: $white;
                }
            }
        }

    }


    &__statistic-count{
        white-space: nowrap;
        color: $red ;
        font-weight: 500;
        font-size: 14px ;
        line-height: 130%;
        display: flex;
        // margin-top: 8px;
    }

    // .reviews__statistic-content

    &__statistic-content {
        display: flex;
        flex-direction: column;
        gap: 3px;
        width: 100%;
    }

    // .reviews__statistic-line

    &__statistic-line {
        display: flex;
        gap: 8px;
        align-items: center;
        width: 100%;
        span{
            color: $red ;
            font-weight: 500;
            font-size: 14px ;
        }
    }

    // .reviews__statistic-line-bg

    &__statistic-line-bg {
        position: relative;
        border-radius: 5px;
        width: 100%;
        height: 8px;
        background: $light-red
    }

    &__statistic-line-bg-active{
        background: rgba(171, 51, 47, 0.654902);
        border-radius: 5px;
        height: 100%;
    }
}

