.blog {

    // .blog__container

    &__container {
        display: flex;
        flex-direction: column;
        gap: 56px;
        @media(max-width:$md3){
            gap: 36px;
        }
    }

    // .blog__filters-init

    &__filters-init {
        display: none;
        margin: 0 auto;
        max-width: 440px;
        width: 100%;
        padding: 12px 32px ;
        gap: 13px;
        border: 1px solid $border;
        background-color: transparent;
        border-radius: 10px;
        text-align: center;
        color: $text3;
        font-size: 16px;
        @media(max-width:$md3){
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    // .blog__filters-init-text

    &__filters-init-text {

    }

    // .blog__filters

    &__filters {
        display: flex;
        flex-direction: column;
        transition: 0.5s;
        @media(max-width:$md3){
            align-items: center;
            gap: 24px;
            position: fixed;
            z-index: 2;
            right: -800px;
            max-height: 100vh;
            height: 100%;
            top: 0;
            padding: 100px 0px  50px 0px;
        }
        &.filtersActive{
            right: 0px;
            transition: 0.5s;
            z-index: 999999999999;
            width: 100%;
            overflow-y: auto;
            background-color: #FFFFFF;
        }
    }

    // .blog__filters-close

    &__filters-close {
        display: none;
        gap: 16px;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid $border;
        padding-bottom: 16px;
        @media(max-width:$md3){
            display: flex;
            padding-left: 15px;
        }
    }

    // .blog__filters-close-text

    &__filters-close-text {
    }

    // .blog__filters-content

    &__filters-content {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        align-items: center;
        @media(max-width:$md3){
            flex-direction: column;
            width: 100%;
            padding: 15px;
        }
    }

    // .blog__button

    &__button {
        width: max-content;
        @media(max-width:$md3){
            max-width: 400px;
            width: 100%;
        }
    }

    // .blog__label

    &__label {
        width: 100%;

        input:checked + .blog__input-text {
            color: $red;
            border-color: $red;
            &:hover{

                background-color: $hover-red;
                border-color: $hover-red;
                color: $white;
            }
            @media(max-width:$md3){
                &:hover{
                    border-color: $red;
                    color: $red;
                    background-color:$white;
                }

            }
        }
    }

    input[type="radio"]:checked+label{
        border-color: $red;
        &:hover{

            background-color: $hover-red;
            border-color: $hover-red;
            color: $white;
        }
        @media(max-width:$md3){
            &:hover{
                border-color: $red;
                color: $red;
                background-color:$white;
            }

        }
    }

    // .blog__input

    &__input {
        position:absolute;
        visibility:hidden;
        height:0px;
        width:0px;
    }

    // .blog__input-text

    &__input-text {
        font-size: 18px;
        line-height: 150%;
        color: $text3;
        padding: 12px 32px;
        border: 1px solid #E3E6EE;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        transition: 0.3s;
        &:hover{

            background-color: $hover-red;
            border-color: $hover-red;
            color: $white;
        }
        @media(max-width:$md3){
            justify-content: center;
            max-width: 500px;
            width: 100%;
            text-align: center;
        }
    }

    // .blog__content

    &__content {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        justify-content: center;
    }

    // .blog__cart

    &__cart {
        max-width: 384px;
        flex: 1 1 340px;
        width: 100%;
        display: flex;
        flex-direction: column;
        background: $white;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
        @media(max-width:$md2){
            max-width: 415px;
            flex: 1 1 280px;
        }
    }

    // .blog__cart-img

    &__cart-img {
        width: 100%;
        max-height: 223px;
        height: 100%;
        border-radius: 10px 10px 0 0;
        img{
            max-height: 223px;
            height: 223px;
            width: 100%;
            object-fit: cover;
            border-radius: 10px 10px 0 0;
        }
    }

    // .blog__cart-padding

    &__cart-padding {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        height: 100%;
        justify-content: space-between;
    }

    // .blog__cart-text

    &__cart-text {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    // .blog__cart-title

    &__cart-title {
        color: $title;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @media(max-width:$md3){
            font-size: 16px;
            line-height: 22px;
        }
    }

    // .blog__cart-info

    &__cart-info {
        color: $subtitle;
        line-height: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    // .blog__cart-link

    &__cart-link {
        width: max-content;
        padding: 16px 32px;
        color: $red;
        border-radius: 10px;
    }
}

