.pop-up {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99;
    display: none;
    justify-content: center;
    align-items: center;

    &[data-open="true"] {
        display: flex;
    }

    &__background {
        width: 100%;
        height: 100%;
        background-color: $blackOpacity;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100;
    }

    &__container {
        max-width: $maxContainerWidth;
        background: $white;
        padding: 44px;
        border-radius: $defoultRadius;
        position: relative;
        z-index: 101;
        max-height: calc(100% - ($paddingForMobileWrapper * 2));
        overflow: auto;

        &.reviews {
            width: 506px;
            max-width: calc(100% - ($paddingForMobileWrapper * 2));
        }
    }
}
