.doctors {

    // .doctors__container

    &__container {
        display: flex;
        flex-direction: column;
        gap: 56px;
        align-items: center;
    }

    // .doctors__title

    &__title {
        color: $title;
    }

    // .doctors__content

    &__content {
        display: flex;
        justify-content: center;
        gap: 32px;
        flex-wrap: wrap;
        position: relative;
        z-index: 1;
    }

    // .doctors__cart

    &__cart {
        flex: 1 1 300px;
        width: 100%;
        max-width: 440px;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
        overflow: hidden;
        transition: 0.2s;
        &:hover{
            box-shadow: 0px 18px 24px rgba(44, 68, 142, 0.1);
            transform: scale(1.02)
        }
    }

    &__cart-block{
        display: flex;
        padding: 32px;
        flex-direction: column;
        gap: 32px;
        height: 100%;
        justify-content: space-between;
    }

    // .doctors__cart-img

    &__cart-img {
        width: 100%;
        max-height: 300px;
        height: 100%;
        border-radius: 10px 10px 0 0;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }


    &__cart-info {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__cart-name {
        color: $title !important;
    }

    // .doctors__cart-about

    &__cart-about {
        font-size: 16px;
        line-height: 150%;
        color: $subtitle;
        @media(max-width:$md3){
            font-size: 14px;
        }
    }

    // .doctors__cart-link

    &__cart-link {
        padding: 16px 32px;
        max-width: max-content;
    }


}

