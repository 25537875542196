.info {

    // .info__container

    &__container {
        display: flex;
        flex-direction: column;
        max-width: 1040px !important;
        gap: 56px;
        align-items: center;
        @media(max-width:$md3){
            gap: 32px;
        }
    }

    // .info__title

    &__title {
        color: $title;
    }

    // .info__content

    &__content {
        max-width: 1010px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 32px;
        @media(max-width:$md3){
            gap: 22px;
        }
    }

    // .info__text

    &__text {
        font-size: 16px;
        line-height: 150% !important;
        color: $text3;
    }

    // .info__block

    &__block {
        display: flex;
        flex-direction: column;
        gap: 22px;
    }

    // .info__block-subtitle

    &__block-subtitle {
        color: $title;
    }

    // .info__links

    &__links {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 32px;
        @media(max-width:$md3){
            grid-template-columns: repeat(1, 1fr);
            gap: 16px;
        }
    }

    // .info__links-list

    &__links-list {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    // .info__link

    &__link {
    }

    // .info__red

    &__red {
        display: flex;
        gap: 13px;
        align-items: center;
        color: $red;
        transition: 0.3s;
        &:hover{
            svg{
                scale: 1.4;
            }
            transition: 0.3s;
            transform: translateX(-20px);
        }
    }
}
