.swiper-services {

    // .swiper-main__img
    .swiper {
    }
    .swiper-wrapper {
        padding-bottom: 36px;
    }
    .swiper-slide {
        position: relative;
        max-height: 600px;
        min-height: 600px;
        height: 100%;
        display: flex;
        @media(max-width:$md3){
            max-height: 438px;
            min-height: 438px;
        }
    }

    &__img {
        position: absolute;
        max-width: 100vw;
        width: 100%;
        max-height: 600px;
        min-height: 600px;
        height: 100%;
        img{
            position: absolute;
            max-width: 100vw;
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
        @media(max-width:$md3){
            max-height: 438px;
            min-height: 438px;
        }
    }

    &__container{
        padding-top: 100px;
        position: relative;
        z-index: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 62px;
        @media(max-width:$md3){
            padding:64px 15px;
            gap: 32px;
        }
        @media(max-width:360px){
            padding:32px 15px;
            gap: 18px;
        }
    }

    // .swiper-main__content

    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        max-width: 600px;
        padding-left: 15px;
    }

    // .swiper-main__background
    &__block{
        background: rgba(255, 255, 255, 0.75);
        width: 60%;
        height: 100%;
        display: flex;
        position: absolute;
        @media(max-width:$md2){
            width: 100%;
        }
    }



    // .swiper-main__content-title

    &__content-title {
        // max-width: 50%;
    }

    // .swiper-main__content-info

    &__content-info {
        position: relative;
        display: flex;
        align-items: center;
        gap: 32px;
        @media(max-width:$md3){
            gap: 24px;
        }
        // max-width: 50%;
    }

    // .swiper-main__content-border

    &__content-border {
        height: 50%;
        min-width: 72px;
        position: relative;
        top: 0;
        border-bottom: 1px solid $subtitle;
        @media(max-width:$md3){
            min-width: 24px;
        }
    }

    // .swiper-main__content-text

    &__content-text {
        display: flex;
        flex-direction: column;
        gap: 16px;
        font-size: 22px;
        line-height: 130%;
        color: $text3;
        @media(max-width:$md3){
            font-size: 16px;
            line-height: 22px;
        }
        ul{
            li{
                margin-left: 20px;
                list-style: outside !important;
                &::marker{
                    border-radius: 100%;
                    color: #811916;
                }
            }
        }
        .checkbox{
            li{
                list-style: none !important;
            }
        }
        .decline{
            li{
                list-style: none !important;
            }
        }
    }
    &__link{
        // margin-top: 10px;
    }
    &__btns{
        margin-top: 10px;
        display: flex;
        gap: 16px;
        align-items: center;
        text-align: center;
        flex-wrap: wrap;
        @media(max-width:$md2){
            flex-direction: column;
            align-items: flex-start;
        }
        @media(min-width:$md2) {
            a {
                min-width: max-content;
            }
        }
    }
    &__price{
        max-width: max-content;
        padding: 13.4px 32px;
        font-size: 14px;
        line-height: 18px;
    }
    .swiper-pagination {
    }
    .swiper-pagination-bullet {
        background-color: #ee2626 !important;
    }
    .swiper-pagination-bullet-active{
        background-color: $red !important;
    }
}



