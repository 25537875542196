




.header {

    // .header__content
    &.header-static{
        position: static;
    }
    &__content {
        display: flex;
        flex-direction: column;
        @media(max-width:$md2){
            flex-direction: column-reverse;
        }
    }

    // .header__top

    &__top {
        background: #F2F4F8;
        padding: 14px 0;
        // display: none;
        @media (max-width: $md2) {
            display: block;
        }
        &.is-main-page{
            display: block;
        }
        .address {
            display: none;
            flex-direction: column;
            justify-content: center;
            gap: 0;
            position: relative;
            &.address-active{
                .address-list{
                    width: auto;
                    height: auto;
                    visibility: visible;
                }
                .address-init{
                    span{
                        transform: rotate(180deg);
                        margin-left: 8px;
                    }
                }
            }
            @media(max-width:$md2){
                display: flex;
            }
        }
        .address-init {
            display: flex;
            align-items: center;
            gap: 2px;
            svg{
                height: 18px;
                min-width: 16px;
            }
            div{
                font-size: 12px;
                color: #000000;
                line-height: 13px;
            }
            span{
                margin-left: 8px;
                display: block;
                transition: 0.3s;
            }
        }
        .address-list {

            max-width: 160px;
            min-width: 170px;
            border-radius: 10px;
            overflow: hidden;
            position: absolute;
            height: 0;
            width: 0;
            transition: 0.3s;
            left: 0;
            top: 0;
            padding-top: 30px;
            visibility: hidden;
            li{
                a{
                    background: $white;
                    padding: 8px 12px 0 8px;
                    cursor: pointer;
                }
                &:first-child{
                    a{
                        border-radius: 10px 10px 0 0;
                    }
                }
                &:last-child{
                    a{
                        padding-bottom: 8px;
                        border-radius: 0px 0px 10px 10px;
                    }
                }
            }
        }
    }

    // .header__top-container

    &__top-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 15px;
    }
    &__top-left-mobile-btn{
        padding: 5px !important;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16.8px;
        text-align: center;
        display: none;
        width: max-content;
        max-width: max-content;
        white-space: nowrap;
        svg{
            path{
                stroke:$white;
            }
        }
        @media(max-width:360px){
            padding: 3px 7px !important;
        }
        @media(max-width:$md2){
            display: flex;

        }
    }
    // .header__top-left
    &__top-left-base{
        max-width: 1090px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    &__top-left-mobile{
        width: 100%;
        position: relative;
        // transition: 0.3s;
        z-index: 1;
        display: none;
        @media(max-width:$md2){
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 12px;
        }
        

    }
    &__top-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1090px;
        width: 100%;
        gap: 30px;
        transition: 0.3s;
        @media(max-width:$md2){
            max-width: 100vw;
            gap: 12px;
        }
    }

    // .header__top-socials

    &__top-socials {
        display: flex;
        gap: 12px;
        align-items: center;
        font-size: 0;
        @media(max-width:$md2){
            display: none;
        }
    }

    &__top-social {
    }

    // .header__top-contact

    &__top-contact {
        max-width: 845px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 15px;
        align-items: center;
        @media(max-width:$md2){
            justify-content: flex-end;
        }
        @media(max-width:360px){
            gap: 0;
        }
    }

    &__top-contacts{
        display: flex;
        align-items: center;
        gap: 6px;
        svg{
            margin-left: 6px;
        }
        @media(max-width:$md2){
            display: none;
        }
    }

    // .header__top-location

    &__top-location {
        p{
            white-space: nowrap;
            color: $text3;
        }
        @media(max-width:$md2){
            display: none;
            margin-bottom: 30px;
            p{
                white-space: initial;
            }
        }
    }

    // .header__top-numbers

    &__top-numbers {
        position: relative;
        @media(max-width:$md2){
            display: none;
        }
    }

    .header__top-numbers-init {

        a {
            @media (max-width: $md3) {
                white-space: nowrap;
                font-size: 12px;
            }

            color:$red;
            span {
                margin-left: 5px;
            }
        }
        @media (max-width:$md2){
            display: none;
        }
    }

    &__top-numbers-init {
        gap: 12px;
        display: flex;
        cursor: pointer;
        align-items: center;
        p{
            white-space: nowrap;
            display: flex;
            gap: 12px;
            align-items: center;
            font-size: 14px;
            line-height: 19px;
            color: $text3;
            @media(max-width:$md2){
                display: none;
            }
        }
        a{
            white-space: nowrap;
        }
        svg{
            path{
                stroke:#AB332F;
            }
        }
        @media(max-width:$md2){
            gap: 4px;
            svg{
                path{
                    stroke:#AB332F;
                }
            }
        }
        &:hover{
            p{
                color: #AB332F;
                transition: 0.2s;
            }
            svg{
                path{
                    stroke:#AB332F;
                    transition: 0.2s;
                }
            }
        }
    }

    // .header__top-numbers-list

    &__top-numbers-list {
        transition: all .3s;
        opacity: 0;
        position: absolute;
        z-index: 3;
        visibility: hidden;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 24px;
        background: $white;
        box-shadow: 0px 6px 20px rgba(44, 68, 142, 0.06);
        border-radius: 10px;
        width: 360px;
        left: -240px;
        p{
            max-width: 362px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            white-space: nowrap;
            color: $red;
        }
        a{
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            color: $text3;
            white-space: nowrap;
            color: $red;
        }
        svg{
            cursor: pointer;
            transition: 0.2s;
            path{
                stroke: $red;
            }
            &:hover{
                scale: 1.5;
                transition: 0.2s;
            }
        }
        @media(max-width:$md2){
            left: -360px;
        }
        @media(max-width:$md4){
            left: -200px;
            width: 200px;
            padding: 12px;
            a{
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    // .header__top-number

    &__top-number {
    }

    // .header__top-right

    &__top-right {
        display: flex;
        justify-content: space-between;
        max-width: 270px;
        // width: 100%;
        gap: 15;
        @media(max-width:$md2){
            display: none;
        }
    }

    // .header__top-personal

    &__top-personal {
        display: flex;
        align-items: center;
        gap: 12px;
        p{
            font-size: 16px;
            line-height: 150%;
            color: $text3;
        }
        &:hover{
            p{
                color: $red;
                transition: 0.2s;
            }
            svg{
                path{
                    stroke:$red;
                    transition: 0.2s;
                }
            }
        }
    }
    &__logo-mobile{
        max-width: 135px !important;
        max-height: 37px;
        min-width: 135px !important;
        min-height: 37px;
        height: 37px !important;
        width: 135px;
        font-size: 0;
        display: none;
        img{
            max-width: 135px !important;
            max-height: 37px;
            min-width: 135px !important;
            width: 100%;
            height: 100%;
        }
        @media(max-width:$md2){
            display: flex;

        }
    }
}
.navbar-locales {
    display: flex;
    align-items: center;
    li{
        list-style: none !important;
        padding: 0 4px !important ;
        a{
            font-size: 14px;
            line-height: 19px;
            color: $subtitle;
        }
        &.active-lang{
            a{
                color: #000000;
            }
        }
        &:nth-child(1){
            border-right: 1px solid $subtitle;
        }
    }
}

.list_active{
    // position: absolute;
    opacity: 1;
    visibility: visible;
    transition: all .3s;
    margin-top: 10px;
}


.header2{
    .header__top{
        display: none;
        @media(max-width:$md2){
            display: flex;
        }
    }

}


.header-warning{
    text-align: center;
    width: 100%;
    padding: 8px;
    background: #ff3131;
    p{
        font-size: 16px;
        color: $white;
    }
    a{
        font-size: 16px;
        color: $white;
        text-underline-offset: 3px;
        text-decoration: underline;
        &:hover{
            color: white;
        }
    }
    .header-warning-item{
        display: none;
        margin: 0 auto;
        text-align: center;
        &.active-notify{
            display: block;
        }
    }
}
.scrolled-down{
    .header__top-left-mobile{
        display: none;
    }
}
