.publication {

    // .publication__container

    &__container {
    }

    // .publication__content

    &__content {
        display: flex;
        flex-direction: column;
        // gap: 50px;
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
    }

    // .publication__text

    &__text {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        line-height: 22px;
        color: #515668;
        ul , ol{
            display: flex;
            flex-direction: column;
            gap: 16px;
            li{
                list-style: outside;
                margin-left: 20px;
            }
        }
        h4{
            font-weight: 500;
            font-size: 20px;
            line-height: 27px;
            color: #272B38;
        }
        @media(max-width:$md2){
            font-size: 14px;
            line-height: 130%;
            h4{
                font-size: 18px;
                line-height: 24px;
            }
        }
    }

    // .publication__info

    &__info {
        color: $text3;
        line-height: 22px;
    }

    // .publication__videos

    &__videos {
        margin-top: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        gap: 32px;
        @media(max-width:$md3){
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
        }
    }

    // .publication__video

    &__video {
        width: 100%;
        max-width: 385px;
        height: 338px;
        @media(max-width:$md3){
            max-width: 510px;
        }
        @media(max-width:$md4){
            height: 232px;
        }
    }

    // .publication__frame

    &__frame {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    // .publication__buttons

    &__buttons {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        gap: 16px;
        .prev{
            svg{
                rotate:180deg;
            }
        }
        @media(max-width:$md3){
            display: flex;
            flex-direction: column-reverse;
            width: 100%;
            align-items: center;
        }
    }

    // .publication__button

    &__button {
        width: max-content;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 16px 40px;
        border: 1px solid #BDC2D2;
        border-radius: 10px;
        color: $title;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        max-width: 320px;
        width: 100%;
        justify-content: center;
        transition: .3s;
        @media(max-width:$md3){
            max-width: 400px;
        }
        svg{
            transition: 0.3s;
        }
        &:hover{
            background-color: $hover-red;
            border-color: $hover-red;
            color: $white;
            svg{
                path{
                    stroke: $white;
                }
            }
        }
    }


}
.container {
}
.prev {
}
